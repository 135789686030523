import { BaseDTO } from '../baseDTO.ts';
import { IEditable } from '../editable.ts';
import { ResourceDTO } from './resourceDTO.ts';

export const ClaimColors = {
  None: 'inherit',
  Read: 'info',
  Create: 'success',
  Modify: 'primary',
  Delete: 'warning',
  Execute: 'error',
} as const;

export type ClaimType = keyof typeof ClaimColors;

export const CLAIM_TYPE_VALUES = Object.keys(ClaimColors) as ClaimType[];

export interface ClaimDTO extends BaseDTO, IEditable {
  type: ClaimType;
  resource: ResourceDTO;
}
