export enum creationModes {
  NONE = 0,
  TERAIN = 1,
  BUILDING = 2,
  MEASUREMENT = 3,
  PATRIMONIU = 4,
  CONDUCTA = 5,
  CAMIN = 6,
  PARCELA,
}
