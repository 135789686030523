import { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import InfoIcon from '@mui/icons-material/Info';
import SaveIcon from '@mui/icons-material/Save';
import {
  Button,
  Checkbox,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Tooltip,
} from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';

import { findReportTypeById } from '../../../models/ReportType';
import Settings from '../../../models/settings/settings.model';
import { useCreatePublicPageSettings } from '../../../store/slices/publicPageApi';
import { useAllReportFieldDefinitions } from '../../../store/slices/reportFieldsDefinitionApi';
import { isAlphaNumeric } from '../../../utils/StringHelper';
import MapPreferencesPane from '../../Preferences/MapPreferencesPane';
import { ModalContext } from '../ModalContext.tsx';

import { NotificationContext } from '../../Notification/Notification';
import { ProgressContext } from '../../Progress/Progress';

interface PublicPageDetails {
  tooltipVisibleFields: number[];
  pageLink: string;
  pageTitle: string;
  pageDefaultZoom: number;
}

const PublicPageCreate = () => {
  const { dispatch: progress } = useContext(ProgressContext);
  const { dispatch: modal } = useContext(ModalContext);
  const [mapSettings, setMapSettings] = useState<Settings>();
  const [createPublicPageSettings, { error, isLoading, isSuccess }] = useCreatePublicPageSettings();

  const [publicPageDetails, setPublicPageDetails] = useState<PublicPageDetails>({
    tooltipVisibleFields: [],
    pageLink: '',
    pageTitle: '',
    pageDefaultZoom: 10,
  });

  const { dispatch: notify } = useContext(NotificationContext);
  const { data: reportFieldsDefinitions } = useAllReportFieldDefinitions();
  const [shouldShowErrors, setShouldShowErrors] = useState(false);

  const handleSelectChange = (event: SelectChangeEvent<typeof publicPageDetails.tooltipVisibleFields>) => {
    const {
      target: { value },
    } = event;

    setPublicPageDetails({
      ...publicPageDetails,
      tooltipVisibleFields: typeof value === 'string' ? value.split(',').map(parseInt) : value,
    });
  };

  const isFormValid = useMemo(() => {
    return (
      publicPageDetails.pageTitle?.length > 2 &&
      isAlphaNumeric(publicPageDetails.pageLink) &&
      publicPageDetails.pageLink?.length > 2
    );
  }, [publicPageDetails.pageTitle, publicPageDetails.pageLink]);

  const renderValue = (ids: number[]) => {
    const selectedOptions = reportFieldsDefinitions?.filter(reportFieldDefinition =>
      ids.includes(reportFieldDefinition.id)
    );
    return selectedOptions?.map(option => `${findReportTypeById(option.reportTypeId)}: ${option.fieldName}`).join(', ');
  };

  const onSave = useCallback(async () => {
    if (!isFormValid) {
      setShouldShowErrors(true);
      return;
    }
    if (mapSettings) {
      createPublicPageSettings({
        mapSettings: mapSettings,
        tooltipVisibleFields: publicPageDetails.tooltipVisibleFields,
        pageLink: publicPageDetails.pageLink,
        pageTitle: publicPageDetails.pageTitle,
        pageDefaultZoom: publicPageDetails.pageDefaultZoom,
      });
      setShouldShowErrors(false);
    }
  }, [mapSettings, publicPageDetails, createPublicPageSettings, isFormValid]);

  useEffect(() => {
    modal?.({
      type: 'actions',
      actions: [
        {
          content: (
            <Button startIcon={<SaveIcon />} key="addPatrimoniu" onClick={onSave} color="primary">
              Salveaza
            </Button>
          ),
        },
      ],
    });
  }, [mapSettings, publicPageDetails, modal, onSave]);

  useEffect(() => {
    if (isSuccess) {
      notify?.({ severity: 'success', message: 'Pagina public a fost creata cu succes' });
      setPublicPageDetails({
        tooltipVisibleFields: [],
        pageLink: '',
        pageTitle: '',
        pageDefaultZoom: 10,
      });
    }

    if (error) {
      const errorMessage =
        `Eroare la crearea paginii: ${(error as { data: { errorMessage: string } })?.data?.errorMessage}` ||
        'Eroare la crearea paginii';
      notify?.({ severity: 'error', message: errorMessage });
    }

    isLoading && progress?.('show');
    !isLoading && progress?.('hide');
  }, [error, isLoading, isSuccess, notify, modal, progress]);

  const showLinkError = () =>
    shouldShowErrors && (!(publicPageDetails.pageLink?.length > 2) || !isAlphaNumeric(publicPageDetails.pageLink));

  const showTitleError = () => shouldShowErrors && !(publicPageDetails.pageTitle?.length > 2);

  const showZoomError = () =>
    shouldShowErrors && (publicPageDetails.pageDefaultZoom <= 0 || publicPageDetails.pageDefaultZoom > 15);

  return (
    <>
      <form onSubmit={onSave}>
        <Grid container rowSpacing={2}>
          <Grid item>
            <MapPreferencesPane setMapPreferencesState={state => setMapSettings(state)} />
          </Grid>

          <Grid item container xs={12} md={12} lg={12} columnSpacing={4}>
            <Grid item xs={6}>
              <FormControl style={{ width: '100%' }}>
                <InputLabel htmlFor="pageDefaultZoom">Zoom pagina</InputLabel>
                <OutlinedInput
                  id="pageDefaultZoom"
                  type="number"
                  inputProps={{
                    min: 5,
                    max: 15,
                  }}
                  onChange={e => setPublicPageDetails({ ...publicPageDetails, [e.target.name]: e.target.value })}
                  error={showZoomError()}
                  value={publicPageDetails.pageDefaultZoom}
                  label="Zoom pagina"
                  name="pageDefaultZoom"
                  required
                />
                <FormHelperText error={showZoomError()}>
                  Zoom-ul implicit al paginii trebuie să fie un număr între 5 și 15
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item style={{ display: 'flex', alignItems: 'center' }}>
              <Tooltip title="Setarea de zoom inițială determină nivelul de apropiere cu care harta va fi afișată la încărcarea inițială. Valoarea mai mare va oferi o vedere mai apropiată, în timp ce o valoare mai mică va oferi o vedere mai largă a hărții.Nivelurile de zoom variază între 1 și 20. În general, un nivel de zoom de 10 reprezintă un oraș, un nivel de zoom de 15 reprezintă nivel de stradă, iar un nivel de zoom de 20 reprezintă clădirile.">
                <InfoIcon />
              </Tooltip>
            </Grid>
          </Grid>

          <Grid item container xs={12} md={12} lg={12} columnSpacing={4}>
            <Grid item xs={6}>
              <FormControl style={{ width: '100%' }}>
                <InputLabel id="tooltipVisibleFields">Campuri vizibile</InputLabel>
                <Select
                  labelId="tooltipVisibleFields-label"
                  id="tooltipVisibleFields"
                  multiple
                  value={publicPageDetails.tooltipVisibleFields}
                  onChange={handleSelectChange}
                  input={<OutlinedInput label="Campuri vizibile" />}
                  renderValue={renderValue}
                >
                  {reportFieldsDefinitions?.map(reportFieldDefinition => (
                    <MenuItem key={reportFieldDefinition.id} value={reportFieldDefinition.id}>
                      <Checkbox
                        checked={publicPageDetails.tooltipVisibleFields.indexOf(reportFieldDefinition.id) > -1}
                      />
                      <ListItemText
                        primary={`${findReportTypeById(reportFieldDefinition.reportTypeId)}: ${
                          reportFieldDefinition.fieldName
                        }`}
                      />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item style={{ display: 'flex', alignItems: 'center' }}>
              <Tooltip title="Campurile vor fi afișate atunci când se plasează cursorul mouse-ului peste o măsurătoare">
                <InfoIcon />
              </Tooltip>
            </Grid>
          </Grid>

          <Grid item container xs={12} md={12} lg={12} columnSpacing={4}>
            <Grid item xs={6}>
              <FormControl style={{ width: '100%' }}>
                <InputLabel htmlFor="pageTitle">Titlu pagina</InputLabel>
                <OutlinedInput
                  id="pageTitle"
                  onChange={e => setPublicPageDetails({ ...publicPageDetails, [e.target.name]: e.target.value })}
                  error={showTitleError()}
                  value={publicPageDetails.pageTitle}
                  label="Titlu pagina"
                  name="pageTitle"
                  required
                />
                <FormHelperText error={showTitleError()}>
                  Titlul paginii trebuie să conțină minim 3 caractere
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item style={{ display: 'flex', alignItems: 'center' }}>
              <Tooltip title="Titlul va apărea în pagina public">
                <InfoIcon />
              </Tooltip>
            </Grid>
          </Grid>

          <Grid item container xs={12} md={12} lg={12} columnSpacing={4}>
            <Grid item xs={6}>
              <FormControl style={{ width: '100%' }}>
                <InputLabel htmlFor="pagelink">Link</InputLabel>
                <OutlinedInput
                  id="pagelink"
                  onChange={e => setPublicPageDetails({ ...publicPageDetails, [e.target.name]: e.target.value })}
                  error={showLinkError()}
                  value={publicPageDetails.pageLink}
                  label="Link"
                  name="pageLink"
                  required
                />
                <FormHelperText error={showLinkError()}>
                  Linkul trebuie să fie alfanumeric și să contină minim 3 caractere
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item style={{ display: 'flex', alignItems: 'center' }}>
              <Tooltip title={`Linkul va fi afișat sub forma '${window.location.origin}/public/link'`}>
                <InfoIcon />
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default PublicPageCreate;
