import create from 'zustand';

import createMapSettingsSlice, { MapSettingsState } from './slices/mapSettingsSlice';
import createBuildingMeasurementsSlice, {
  BuildingMeasurementsState,
} from './slices/measurements/buildingMeasurementsSlice';
import createEmptyMeasurementsSlice, { EmptyMeasurementsState } from './slices/measurements/emptyMeasurementsSlice';
import createParcelMeasurementsSlice, { ParcelMeasurementsState } from './slices/measurements/parcelMeasurementsSlice';
import createPatrimonyMeasurementsSlice, {
  PatrimonyMeasurementsState,
} from './slices/measurements/patrimonyMeasurementsSlice';
import createPipeMeasurementsSlice, { PipeMeasurementsState } from './slices/measurements/pipeMeasurementsSlice';
import createSewerMeasurementsSlice, { SewerMeasurementsState } from './slices/measurements/sewerMeasurementsSlice';
import createTerrainMeasurementsSlice, {
  TerrainMeasurementsState,
} from './slices/measurements/terrainMeasurementsSlice';
import createMeasurementsSlice, { MeasurementsState } from './slices/measurementsSlice';
import createOrtophotoplanSlice, { OrtophotoplanState } from './slices/ortophotoplan/ortophotoplanSlice';
import createSettingsSlice, { SettingsState } from './slices/settingsSlice';

export type GlobalState = MeasurementsState &
  SettingsState &
  TerrainMeasurementsState &
  BuildingMeasurementsState &
  PatrimonyMeasurementsState &
  PipeMeasurementsState &
  SewerMeasurementsState &
  EmptyMeasurementsState &
  ParcelMeasurementsState &
  MapSettingsState &
  OrtophotoplanState;

const useStore = create<GlobalState>((...params) => ({
  ...createSettingsSlice(...params),
  ...createMeasurementsSlice(...params),
  ...createTerrainMeasurementsSlice(...params),
  ...createBuildingMeasurementsSlice(...params),
  ...createPatrimonyMeasurementsSlice(...params),
  ...createPipeMeasurementsSlice(...params),
  ...createSewerMeasurementsSlice(...params),
  ...createEmptyMeasurementsSlice(...params),
  ...createParcelMeasurementsSlice(...params),
  ...createMapSettingsSlice(...params),
  ...createOrtophotoplanSlice(...params),
}));

export default useStore;
