import Carousel from 'react-material-ui-carousel';

import { DialogContent, DialogTitle } from '@mui/material';

import { GalleryItem } from '../../models/gallery-item.model';
import { baseApiDomain } from '../../store/settings/Local';

type Props = {
  items: GalleryItem[];

  galleryName: string;
};

const MultiYearGalleryCarousel = ({ items, galleryName }: Props) => {
  return (
    <>
      <DialogTitle>{galleryName}</DialogTitle>
      <DialogContent>
        <Carousel autoPlay={false} navButtonsAlwaysVisible={true}>
          {items.map(item => (
            <img alt="" style={{ width: '100%' }} key={item.id} src={`${baseApiDomain}/${item.fileLink}`} />
          ))}
        </Carousel>
      </DialogContent>
    </>
  );
};

export default MultiYearGalleryCarousel;
