import { UserDTO } from '../../models/DTO/users/userDTO';
import { sgdeApi } from '../index';

const usersApi = sgdeApi.enhanceEndpoints({ addTagTypes: ['CurrentUser', 'Users'] }).injectEndpoints({
  endpoints: build => ({
    getCurrentUser: build.query<UserDTO, void>({
      query: () => '/users/current',
      providesTags: ['CurrentUser'],
    }),
    getUsers: build.query<UserDTO[], void>({
      query: () => '/users',
      providesTags: ['Users'],
    }),
    updateUser: build.mutation<void, Pick<UserDTO, 'username' | 'email'>>({
      query: user => ({
        url: '/users',
        method: 'PATCH',
        body: user,
      }),
      invalidatesTags: ['CurrentUser', 'Users'],
    }),
    recoverUsers: build.mutation<void, number[]>({
      query: ids => ({ url: `/users/${ids.join(',')}`, method: 'PATCH' }),
      invalidatesTags: ['Users'],
    }),
    deleteUsers: build.mutation<void, number[]>({
      query: ids => ({ url: `/users/${ids.join(',')}`, method: 'DELETE' }),
      invalidatesTags: ['Users'],
    }),
    addUserRole: build.mutation<void, { id: number; roleId: number }>({
      query: ({ id, roleId }) => ({ url: `/users/${id}/roles`, method: 'POST', body: { id: roleId } }),
      invalidatesTags: ['Users'],
    }),
    removeRole: build.mutation<void, { id: number; roleId: number }>({
      query: ({ id, roleId }) => ({ url: `/users/${id}/roles/${roleId}`, method: 'DELETE' }),
      invalidatesTags: ['Users'],
    }),
  }),
});

export const {
  useGetCurrentUserQuery: useCurrentUser,
  useGetUsersQuery: useUsers,
  useUpdateUserMutation: useUpdateUser,
  useDeleteUsersMutation: useDeleteUsers,
  useRecoverUsersMutation: useRecoverUsers,
  useAddUserRoleMutation: useAddUserRole,
  useRemoveRoleMutation: useRemoveUserRole,
} = usersApi;
