const baseApiDomain = (window as any)?.config?.baseApiDomain;
if (!baseApiDomain) {
  throw new Error('Missing baseApiDomain, configure in config.js file');
}

const blobStorageUrl = (window as any)?.config?.blobStorageUrl;
if (!blobStorageUrl) {
  throw new Error('Missing blobStorageUrl, configure in config.js file');
}

const azureB2cInstance = (window as any)?.config?.azureB2cInstance;
if (!azureB2cInstance) {
  throw new Error('Missing azureB2cInstance, configure in config.js file');
}

const azureB2cDomain = (window as any)?.config?.azureB2cDomain;
if (!azureB2cDomain) {
  throw new Error('Missing azureB2cDomain, configure in config.js file');
}

const azureB2cTenantId = (window as any)?.config?.azureB2cTenantId;
if (!azureB2cTenantId) {
  throw new Error('Missing azureB2cTenantId, configure in config.js file');
}

const azureB2cClientId = (window as any)?.config?.azureB2cClientId;
if (!azureB2cClientId) {
  throw new Error('Missing azureB2cClientId, configure in config.js file');
}

const menuTitle = (window as any)?.config?.menuTitle;
if (!menuTitle) {
  throw new Error('Missing menuTitle, configure in config.js file');
}

const appInsightsConnectionString = (window as any)?.config?.appInsightsConnectionString;
if (!appInsightsConnectionString) {
  throw new Error('Missing appInsightsConnectionString, configure in config.js file');
}

const reportsApiUrl = `${baseApiDomain}/api/v2`;

export {
  appInsightsConnectionString,
  azureB2cClientId,
  azureB2cDomain,
  azureB2cInstance,
  azureB2cTenantId,
  baseApiDomain,
  blobStorageUrl,
  menuTitle,
  reportsApiUrl,
};
