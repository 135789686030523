import { ChangeEvent, Reducer, useContext, useReducer, useState } from 'react';

import { Button, DialogActions, DialogContent, DialogTitle, Grid, TextField } from '@mui/material';

import { ReportType } from '../../models/ReportType';
import { DocumentLibrary } from '../../models/document-library.model';
import SGDEFeature from '../../models/feature/sgde-feature';
import { Gallery } from '../../models/gallery.model';
import { Inventory } from '../../models/inventory.model';
import OpenLayersMap from '../../models/map/open-layers-map.model';
import { AreaMeasurement } from '../../models/measurement.model';
import { ReportFieldOptional } from '../../models/report-field.model';
import { NotificationContext } from '../../primitives/Notification/Notification';
import { ProgressContext } from '../../primitives/Progress/Progress';
import { postResource, putResource } from '../../store/Fetch';
import { reportsApiUrl } from '../../store/settings/Local';
import useStore from '../../store/useStore';
import DynamicForm from '../Form/DynamicForm/DynamicForm';

const initialState = {};

interface Action<T> {
  reportFieldDefinitionId: number;
  files: T;
}

interface State<T> {
  [key: string]: T;
}

const createReducer =
  <T,>() =>
  (state: State<T>, action: Action<T>) => ({ ...state, [action.reportFieldDefinitionId]: action.files });

type Props = {
  map: OpenLayersMap;
  feature: SGDEFeature;
  existingMeasurement?: AreaMeasurement;
  closeDialog: () => void;
  showReportDialog?: (features: SGDEFeature[]) => void;
};

const CreateCamin = ({ feature, existingMeasurement, closeDialog, map }: Props) => {
  const { dispatch: progress } = useContext(ProgressContext);
  const { dispatch: notify } = useContext(NotificationContext);
  const [additionalReportFields, setAdditionalReportFields] = useState<ReportFieldOptional[]>([]);
  const [name, setName] = useState('');
  const [photos, setPhotos] = useReducer<Reducer<State<Gallery>, Action<Gallery>>>(
    createReducer<Gallery>(),
    initialState
  );
  const [documents, setDocuments] = useReducer<Reducer<State<DocumentLibrary>, Action<DocumentLibrary>>>(
    createReducer<DocumentLibrary>(),
    initialState
  );
  const [inventory, setInventory] = useState<Inventory>();
  const [error, setError] = useState(true);
  const [isValid, setIsValid] = useState(false);
  const addMeasurement = useStore(state => state.addMeasurement);

  const onSave = async () => {
    try {
      progress?.('show');
      let measurement = existingMeasurement;
      let measurementReportFieldValue = existingMeasurement?.id;

      if (!measurement) {
        measurement = await addMeasurement(feature.getAreaMeasurement());
        measurementReportFieldValue = measurement?.parentMeasurementId;
      }

      const galleryFields: ReportFieldOptional[] = [];
      for (const galleryFieldId in photos) {
        const galleryPhotos = photos[galleryFieldId];
        const photosFormData = new FormData();
        if (galleryPhotos?.files?.length > 0) {
          for (const photo of galleryPhotos.files) {
            photosFormData.append(photo.name, photo);
          }
        }

        const galleryResult = await fetch(`${reportsApiUrl}/gallery/-1`, {
          method: 'POST',
          body: photosFormData,
        });

        const gallery = await galleryResult.json();

        const galleryReportField = {
          reportFieldDefinitionId: parseInt(galleryFieldId),
          value: String(gallery?.mediaGalleryId),
        };

        galleryFields.push(galleryReportField);
      }

      const documentsReportFields: ReportFieldOptional[] = [];
      for (const documentFieldId in documents) {
        const documentLibrary = documents[documentFieldId];
        const documentsFormData = new FormData();
        if (documentLibrary?.files?.length > 0) {
          for (const document of documentLibrary.files) {
            documentsFormData.append(document.name, document);
          }
        }

        const libraryResult = await fetch(`${reportsApiUrl}/documents/-1`, {
          method: 'POST',
          body: documentsFormData,
        });

        const library = await libraryResult.json();

        const documentsReportField = {
          reportFieldDefinitionId: parseInt(documentFieldId),
          value: String(library?.library?.id),
        };

        documentsReportFields.push(documentsReportField);
      }

      const createdInventory = await putResource(`${reportsApiUrl}/inventory/`, {
        inventoryItems: inventory?.items,
      });

      const inventoryReportField = {
        reportFieldDefinitionId: 42,
        value: String(createdInventory.id),
      };

      const report = {
        reportTypeId: 5,
        createdBy: 1,
        name: name,
        reportFields: [
          {
            reportFieldDefinitionId: 36,
            value: String(true),
          },
          {
            reportFieldDefinitionId: 1,
            value: String(measurement.area),
          },
          {
            reportFieldDefinitionId: 35,
            value: String(measurementReportFieldValue),
          },
          inventoryReportField,
          ...additionalReportFields,
        ],
      };

      report.reportFields = [...report.reportFields, ...galleryFields, ...documentsReportFields];

      const createdReport = await postResource(`${reportsApiUrl}/reports`, report);

      const measurementGalleryId = galleryFields?.[0]?.value;
      if (measurementGalleryId) {
        const areaMeasurement = feature.getAreaMeasurement();
        await postResource(
          `${reportsApiUrl}/measurements/${measurement.id}/report/${createdReport.id}?area=${areaMeasurement.area}&centerLat=${areaMeasurement.lat}&centerLng=${areaMeasurement.lng}`
        );
      }

      map.reloadMeasurementsLayer(ReportType.Camin);
      closeDialog();
      notify?.({ severity: 'success', message: 'Camin creat cu succes' });
    } catch (e) {
      notify?.({ severity: 'error', message: 'Eroare la crearea caminului' });
    } finally {
      progress?.('hide');
    }
  };

  const onNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    const targetValue = event.target.value;
    if (targetValue && targetValue.length > 0) {
      setError(false);
      return setName(targetValue);
    }

    setError(true);
  };

  return (
    <>
      <DialogTitle>Creaza raport Camin</DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item xs={12}>
            <TextField
              fullWidth
              error={error}
              required
              onChange={onNameChange}
              variant="outlined"
              margin="normal"
              label="Nume"
            />
            <DynamicForm
              reportTypeId={5}
              onValidityChange={setIsValid}
              readonly={false}
              onValueChanged={setAdditionalReportFields}
              onGalleryChanged={(reportFieldDefinitionId, files) => setPhotos({ reportFieldDefinitionId, files })}
              onDocumentsChange={(reportFieldDefinitionId, files) => setDocuments({ reportFieldDefinitionId, files })}
              onMultiYearChange={setInventory}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button color="primary" disabled={error || !isValid} onClick={onSave}>
          Salveaza
        </Button>
      </DialogActions>
    </>
  );
};

export default CreateCamin;
