import { MultipleItemDto } from '../../models/DTO/multiple/multipleItemDTO';
import { ReportResourceDTO } from '../../models/DTO/report-fields/report-resourceDTO';
import { MultipleFieldDefinition } from '../../models/multiple-field-definition';
import { ReportFieldDefinition } from '../../models/report-field-definition.model';
import { ReportField } from '../../models/report-field.model';
import { sgdeApi } from '../index';

const reportFieldDefinitionsApi = sgdeApi
  .enhanceEndpoints({
    addTagTypes: ['AllReportFieldsDefinitions', 'MultipleFieldsDefinitions', 'MultipleItems', 'ReportFields'],
  })
  .injectEndpoints({
    endpoints: build => ({
      getAllReportFieldDefinitions: build.query<ReportFieldDefinition[], void>({
        query: () => `/reportFieldsDefinition/fields`,
        providesTags: ['AllReportFieldsDefinitions'],
      }),
      getMultipleFieldDefinitions: build.query<MultipleFieldDefinition[], { multipleFieldDefinitionIds: number[] }>({
        query: ({ multipleFieldDefinitionIds }) => `/multiple/definitions/${multipleFieldDefinitionIds.join(',')}`,
        providesTags: ['MultipleFieldsDefinitions'],
      }),
      getReportFieldsDefinitions: build.query<ReportResourceDTO, { reportTypeId: number }>({
        query: ({ reportTypeId }) => `/reports/definitions/${reportTypeId}`,
      }),
      getReportFields: build.query<ReportField[], { reportId: number }>({
        query: ({ reportId }) => `/report/${reportId}/fields`,
        providesTags: ['ReportFields'],
        keepUnusedDataFor: 0,
      }),
      getMultipleItems: build.query<MultipleItemDto[], { multipleId: number }>({
        query: ({ multipleId }) => `/multiple/${multipleId}/items`,
        providesTags: ['MultipleItems'],
      }),
    }),
  });

export const {
  useGetAllReportFieldDefinitionsQuery: useAllReportFieldDefinitions,
  useGetMultipleFieldDefinitionsQuery: useMultipleFieldDefinitions,
  useGetReportFieldsDefinitionsQuery: useReportFieldsDefinitions,
  useGetReportFieldsQuery: useReportFields,
  useGetMultipleItemsQuery: useMultipleItems,
} = reportFieldDefinitionsApi;
