import { ChangeEvent } from "react";

import { SelectChangeEvent } from "@mui/material";
import TextField from "@mui/material/TextField";

import { DateTime } from "luxon";

type IChangeEvent =
  | ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  | SelectChangeEvent<string | number>
  | { target: { value: string } };

interface INativeDateTimePickerProps {
  disabled: boolean;
  name: string;
  reportFieldId: number;
  value?: string;
  onChange: (event: IChangeEvent) => void;
}

export const NativeDateTimePicker = (props: INativeDateTimePickerProps) => {
  const { disabled, name, value, onChange } = props;

  const handleChange = (event: IChangeEvent) => {
    if (event.target.value === "") {
      onChange({ target: { value: "-" } });
      return;
    }

    const isoFormatDate = new Date(event.target.value).toISOString();
    onChange({ target: { value: isoFormatDate } });
  };

  return (
    <TextField
      disabled={disabled}
      label={name}
      type="datetime-local"
      value={
        value ? DateTime.fromISO(value).toFormat("yyyy-MM-dd'T'HH:mm") : "-"
      }
      sx={{ width: 250, maxWidth: "100%", mt: 1 }}
      InputLabelProps={{ shrink: true }}
      onChange={handleChange}
    />
  );
};
