import PublicPageSetting from '../../models/public-page-model';
import { sgdeApi } from '../index';

const publicPageApi = sgdeApi.enhanceEndpoints({ addTagTypes: ['PublicPage', 'PublicPages'] }).injectEndpoints({
  endpoints: build => ({
    getAllPublicPageSettings: build.query<PublicPageSetting[], void>({
      query: () => `/publicPage`,
      providesTags: ['PublicPages'],
    }),
    getPublicPageSettings: build.query<PublicPageSetting, number>({
      query: id => `/publicPage/${id}`,
      providesTags: ['PublicPage'],
    }),
    getPublicPageSettingsByName: build.query<PublicPageSetting, string>({
      query: name => `/publicPage/name/${name}`,
      providesTags: ['PublicPage'],
    }),
    createPublicPageSettings: build.mutation<void, PublicPageSetting>({
      query: publicPageSettings => ({ url: `/publicPage`, method: 'POST', body: publicPageSettings }),
      invalidatesTags: ['PublicPages'],
    }),
    deletePublicPageSettings: build.mutation<void, number>({
      query: id => ({
        url: `/publicPage/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['PublicPage', 'PublicPages'],
    }),
  }),
});

export const {
  useGetAllPublicPageSettingsQuery: useAllPublicPageSettings,
  useGetPublicPageSettingsQuery: usePublicPageSettings,
  useGetPublicPageSettingsByNameQuery: usePublicPageSettingsByName,
  useCreatePublicPageSettingsMutation: useCreatePublicPageSettings,
  useDeletePublicPageSettingsMutation: useDeletePublicPageSettings,
} = publicPageApi;
