import { Coordinate } from "ol/coordinate";
import { Geometry, LineString, Polygon } from "ol/geom";
import { GpsCoordinate } from "../coordinate.model";
import CustomCoordinate from "../custom-coordinate.model";
import { CreateAreaMeasurement, GeometryType } from "../measurement.model";
import SGDEFeature from "./sgde-feature";

function getAreaMeasurement(feature: SGDEFeature): CreateAreaMeasurement {
  const geometry = feature?.getGeometry();
  if (!geometry)
    throw new Error(
      "can't create area measurement for feature without geomtry",
    );

  return createAreaMeasurement(geometry);
}

function createAreaMeasurement(geometry: Geometry): CreateAreaMeasurement {
  if (geometry instanceof Polygon) {
    const area = geometry.getArea();
    return createPolygonAreaMeasurement(geometry, area);
  }

  if (geometry instanceof LineString) {
    return createLineAreaMeasurement(geometry);
  }

  throw new Error("unsupported geomtry type");
}

function createPolygonAreaMeasurement(
  polygon: Polygon,
  area: number,
): CreateAreaMeasurement {
  const customCoordinates = polygon.getCoordinates();
  const transformed = polygon.clone().transform("EPSG:3844", "EPSG:4326");
  const transformedCoordinates = (transformed as Polygon).getCoordinates();
  const gpsCoordinates = extractCoordinates(
    transformedCoordinates,
    cordTransformFunc,
  );
  const mediumLat =
    gpsCoordinates[0].reduce((acc, curr) => acc + curr.lat, 0) /
    transformedCoordinates[0].length;
  const mediumLng =
    gpsCoordinates[0].reduce((acc, curr) => acc + curr.lng, 0) /
    transformedCoordinates[0].length;
  return {
    coordinatesArray:
      extractCoordinates(transformedCoordinates, cordTransformFunc) ?? [],
    customCoordinatesArray:
      extractCoordinates(customCoordinates, customCordTransformFunc) ?? [],
    customProjection: 3844,
    area: area,
    lat: mediumLat,
    lng: mediumLng,
    zoomLevel: 19,
    height: 500,
    width: 700,
    geometryType: GeometryType.Polygon,
    isReportPristine: false,
  };
}

function createLineAreaMeasurement(line: LineString): CreateAreaMeasurement {
  const customCoordinates = line.getCoordinates();
  const transformed = line.clone().transform("EPSG:3844", "EPSG:4326");
  const transformedCoordinates = (transformed as LineString).getCoordinates();
  const gpsCoordinates = extractCoordinates(
    [transformedCoordinates],
    cordTransformFunc,
  );
  const mediumLat =
    gpsCoordinates[0].reduce((acc, curr) => acc + curr.lat, 0) /
    transformedCoordinates[0].length;
  const mediumLng =
    gpsCoordinates[0].reduce((acc, curr) => acc + curr.lng, 0) /
    transformedCoordinates[0].length;
  return {
    coordinatesArray:
      extractCoordinates([transformedCoordinates], cordTransformFunc) ?? [],
    customCoordinatesArray:
      extractCoordinates([customCoordinates], customCordTransformFunc) ?? [],
    customProjection: 3844,
    area: 0,
    lat: mediumLat,
    lng: mediumLng,
    zoomLevel: 19,
    height: 500,
    width: 700,
    geometryType: GeometryType.LineString,
    isReportPristine: false,
  };
}

function extractCoordinates<CoordType>(
  olCoordinates: Coordinate[][],
  transformFunc: (cord: Coordinate) => CoordType,
): CoordType[][] {
  return olCoordinates?.map(
    (ca) =>
      ca?.reduce((pc: CoordType[], cc: Coordinate): CoordType[] => {
        if (cc[0] && cc[1]) {
          pc.push(transformFunc(cc));
        }

        return pc;
      }, []) || [],
  );
}

function customCordTransformFunc(cord: Coordinate): CustomCoordinate {
  return {
    x: cord[0],
    y: cord[1]!,
  };
}

function cordTransformFunc(cord: Coordinate): GpsCoordinate {
  return {
    lat: cord[1],
    lng: cord[0],
  };
}

type CustomStylingField = {
  id: number;
  value: string;
};

export type { CustomStylingField };
export { getAreaMeasurement };
