import { Button, Grid } from '@mui/material';
import { useContext, useEffect } from 'react';
import ReactPannellum from 'react-pannellum';
import { ModalContext } from '../Modal/ModalContext';
import PanoramaEditor from './PanoramaEditor';

type Props = {
  imagePath?: string;

  imageName?: string;
};

const PanoramaViewer = ({ imagePath, imageName }: Props) => {
  const { dispatch: modal } = useContext(ModalContext);

  const config = {
    autoRotate: -2,
    autoLoad: true,
  };

  const onPanoramaEdit = () => {
    modal?.({
      type: 'open',
      title: `Editare imagine 360 (${imageName})`,
      mainContent: <PanoramaEditor imageName={imageName} imagePath={imagePath}></PanoramaEditor>,
    });
  };

  useEffect(() => {
    modal?.({
      type: 'actions',
      actions: [
        {
          content: (
            <Button color="primary" onClick={onPanoramaEdit} key="edit-panorama">
              Editeaza
            </Button>
          ),
        },
      ],
    });
  }, [imageName, imagePath]);

  return (
    <Grid>
      <Grid item xs={12}>
        <ReactPannellum
          id="1"
          sceneId="panoramaMainScene"
          style={{ width: '100%', height: '600px' }}
          imageSource={imagePath}
          config={config}
        />
      </Grid>
    </Grid>
  );
};

export default PanoramaViewer;
