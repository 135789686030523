import AuthData from '../../models/auth-data';

import { sgdeApi } from '../index';

const authenticationApi = sgdeApi.enhanceEndpoints({ addTagTypes: ['CurrentUser'] }).injectEndpoints({
  endpoints: build => ({
    login: build.mutation<void, AuthData>({
      query: authenticationData => ({
        url: '/authentication',
        method: 'POST',
        body: authenticationData,
      }),
      invalidatesTags: ['CurrentUser'],
    }),
    logout: build.mutation<void, void>({
      query: () => ({ url: '/authentication', method: 'DELETE' }),
      invalidatesTags: ['CurrentUser'],
    }),
    updatePassword: build.mutation<void, string>({
      query: password => ({
        url: '/authentication/password',
        method: 'PATCH',
        body: { password },
      }),
    }),
  }),
});

export const {
  useLoginMutation: useLogin,
  useLogoutMutation: useLogout,
  useUpdatePasswordMutation: useUpdatePassword,
} = authenticationApi;
