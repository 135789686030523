import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  multiple: {
    display: 'flex',
    flexDirection: 'column',
  },
  multipleLine: {
    gap: 10,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',

    '& .MuiFormControl-root': {
      flex: '1 0 20%',
    },
  },
}));
