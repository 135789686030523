import Feature from 'ol/Feature';
import { Extent } from 'ol/extent';
import GeoJSON from 'ol/format/GeoJSON';
import { Circle, Geometry, Point } from 'ol/geom';
import VectorImageLayer from 'ol/layer/VectorImage';
import { bbox } from 'ol/loadingstrategy';
import { Projection, transform } from 'ol/proj';
import { Vector } from 'ol/source';
import VectorSource from 'ol/source/Vector';
import { Fill, Stroke, Style } from 'ol/style';

import { mapBoundsFromExtent } from './map-utils';

interface StreetviewLayerOptions {
  sourceUrl: string;

  zIndex?: number;

  year: number;
}

class StreetviewLayer extends VectorImageLayer<VectorSource<Geometry>> {
  vectorSource: Vector;

  constructor(private options: StreetviewLayerOptions) {
    const style = new Style({
      fill: new Fill({
        color: 'rgba(207, 99, 17, 0.5)',
      }),
      stroke: new Stroke({
        color: 'rgba(207, 99, 17, 0.6)',
        width: 2,
      }),
    });

    super({
      style,
      zIndex: options.zIndex,
    });

    this.vectorSource = new Vector({
      loader: (extent: Extent, resolution: number, projection: Projection) =>
        this.loader(extent, resolution, projection),
      format: new GeoJSON(),
      strategy: bbox,
    });

    this.setSource(this.vectorSource);
  }

  // write a method that calculates fibonacci numbers until a given max value
  // and returns them in an array
  // the method should be called fibonacci and should have one parameter
  // the parameter should be the max value
  public async loader(extent: Extent, _: number, projection: Projection) {
    const url = this.options.sourceUrl;

    const mapBounds = mapBoundsFromExtent(extent);
    const projectedMapBounds = mapBounds.map(c => transform(c, 'EPSG:3844', 'EPSG:4326'));
    const res = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        year: this.options.year,
        boundingBox: projectedMapBounds,
      }),
    });

    if (!res.body) return;
    const reader = res.body?.getReader();

    const textDecoder = new TextDecoder('utf-8');
    let buffer = '';
    const format = this.vectorSource.getFormat();
    while (true) {
      const { value: chunk, done } = await reader.read();
      buffer += textDecoder.decode(chunk || new Uint8Array(), { stream: !done });

      let lineEnd;
      while ((lineEnd = buffer.indexOf('#')) !== -1) {
        const line = buffer.slice(0, lineEnd);
        buffer = buffer.slice(lineEnd + 1);

        const data = JSON.parse(line);
        const featureLike = format?.readFeature(data, { featureProjection: projection, dataProjection: projection });

        if (featureLike) {
          const feature = featureLike as Feature;
          const point = feature.getGeometry() as Point;
          const coordinates = point.getCoordinates();
          const projectedCoordinates = transform(coordinates, 'EPSG:4326', 'EPSG:3844');
          const circle = new Circle(projectedCoordinates, 5);
          feature.setGeometry(circle);
          this.vectorSource.addFeature(feature);
        }
      }

      if (done) {
        break;
      }
    }
  }
}

export default StreetviewLayer;
