import { ChangeEvent, SyntheticEvent, useContext, useReducer, useState } from 'react';

import { Autocomplete, DialogActions, DialogContent, DialogTitle, Grid } from '@mui/material';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

import SGDEFeature from '../../models/feature/sgde-feature';
import OpenLayersMap from '../../models/map/open-layers-map.model';
import { AreaMeasurement, CreateAreaMeasurement } from '../../models/measurement.model';
import { ReportField } from '../../models/report-field.model';
import { ModalContext } from '../../primitives/Modal/ModalContext';
import DynamicForm from '../Form/DynamicForm/DynamicForm';
import LinkBuilding from './LinkBuilding';

type Props = {
  map: OpenLayersMap;
  feature: SGDEFeature;
  closeDialog: () => void;
  existingMeasurement?: AreaMeasurement;
  showReportDialog?: (features: SGDEFeature[]) => void;
};

const initialState = {};
function reducer(state: any, action: any) {
  return {
    ...state,
    [parseInt(action.reportFieldDefinitionId)]: action.files,
  };
}

const CreateBuilding = ({ feature, closeDialog, existingMeasurement, showReportDialog, map }: Props) => {
  const { dispatch: modal } = useContext(ModalContext);
  const [additionalReportFields, setAdditionalReportFields] = useState<ReportField[]>([]);
  const [name, setName] = useState<string>('');
  const [photos, setPhotos] = useReducer(reducer, initialState);
  const [documents, setDocuments] = useReducer(reducer, initialState);
  const [error, setError] = useState<boolean>(true);
  const [isValid, setIsValid] = useState<boolean>(false);

  const onNextStep = async () => {
    const report = {
      reportTypeId: 2,
      createdBy: 1,
      name: name,
    };

    let areaMeasurement: CreateAreaMeasurement | AreaMeasurement;
    if (existingMeasurement) {
      areaMeasurement = existingMeasurement;
    } else {
      areaMeasurement = feature.getAreaMeasurement();
    }

    modal?.({
      type: 'open',
      title: 'Legare imobil',
      mainContent: (
        <LinkBuilding
          feature={feature}
          map={map}
          report={report}
          additionalReportFields={additionalReportFields}
          existingMeasurement={existingMeasurement}
          measurement={areaMeasurement}
          closeDialog={closeDialog}
          photos={photos}
          documents={documents}
          showReportDialog={showReportDialog}
        />
      ),
    });
  };

  const onNameChange = (_: SyntheticEvent<Element, Event>, value: string | null) => {
    if (value && value.length > 0) {
      setName(value);
      return setError(false);
    }

    setError(true);
  };

  const onNameInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (value && value.length > 0) {
      setName(value);
      return setError(false);
    }

    setError(true);
  };

  return (
    <>
      <DialogTitle>Creare raport Constructie</DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item xs={12}>
            <Autocomplete<string>
              freeSolo={true as unknown as undefined}
              fullWidth
              options={[...Array(15)].map((_, idx) => `CLADIRE ${idx + 1}`)}
              renderInput={params => (
                <TextField
                  {...params}
                  label="Nume"
                  margin="normal"
                  id="standard-basic"
                  variant="outlined"
                  error={error}
                  InputProps={{
                    ...params.InputProps,
                    type: 'search',
                  }}
                  onChange={onNameInputChange}
                />
              )}
              onChange={onNameChange}
            />
            <DynamicForm
              reportTypeId={2}
              onValidityChange={setIsValid}
              readonly={false}
              onValueChanged={setAdditionalReportFields}
              onGalleryChanged={(reportFieldDefinitionId, files) => setPhotos({ ...files, reportFieldDefinitionId })}
              onDocumentsChange={(reportFieldDefinitionId, files) =>
                setDocuments({ ...files, reportFieldDefinitionId })
              }
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button color="primary" disabled={error || !isValid} onClick={onNextStep}>
          Continua
        </Button>
      </DialogActions>
    </>
  );
};

export default CreateBuilding;
