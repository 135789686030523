import { Delete as DeleteIcon, RestoreFromTrash as RecoverIcon } from '@mui/icons-material';
import { IconButton, Toolbar, Tooltip, Typography, alpha } from '@mui/material';

import { useDeleteUsers, useRecoverUsers } from '../../store/slices/usersApi';

interface Props {
  selected: readonly number[];
}

export const UsersTableToolbar = ({ selected }: Props) => {
  const [recoverUsers] = useRecoverUsers();
  const [deleteUsers] = useDeleteUsers();

  return (
    <Toolbar
      sx={{
        ...(selected.length > 0 && {
          bgcolor: theme => alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        }),
      }}
    >
      {!!selected.length && (
        <>
          <Typography sx={{ flex: '1 1 100%' }} color="inherit" variant="subtitle1" component="div">
            {selected.length} selectati
          </Typography>

          <Tooltip title="Restabileste">
            <IconButton onClick={() => recoverUsers([...selected])}>
              <RecoverIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Sterge">
            <IconButton onClick={() => deleteUsers([...selected])}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </>
      )}
    </Toolbar>
  );
};
