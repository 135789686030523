import { TextField as MuiTextField, TextFieldProps } from '@mui/material';

import { FormReportFieldDefinition } from '../../../models/form-report-field-definition.model';

const TextField = (props: TextFieldProps) => <MuiTextField {...props} />;

interface IReportProps {
  report: FormReportFieldDefinition;
  type?: TextFieldProps['type'];
  disabled?: TextFieldProps['disabled'];
  multiline?: TextFieldProps['multiline'];
  onChange?: TextFieldProps['onChange'];
}

export const ReportTextField = ({ report, type, disabled, multiline, onChange }: IReportProps) => (
  <TextField
    id={report.id.toString()}
    name={report.id.toString()}
    label={report.fieldName}
    value={report.value ?? ''}
    required={report.validationRequired}
    error={report.validationRequired && !report.value?.toString().length}
    disabled={disabled}
    multiline={multiline}
    fullWidth
    type={type}
    margin="normal"
    autoComplete={report.fieldName}
    helperText={report.helperText}
    onChange={onChange}
  />
);

export default TextField;
