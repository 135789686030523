import { Button, DialogActions, DialogTitle } from '@mui/material';
import { useContext } from 'react';
import { ReportType } from '../../models/ReportType';
import SGDEFeature from '../../models/feature/sgde-feature';
import OpenLayersMap from '../../models/map/open-layers-map.model';
import { NotificationContext } from '../../primitives/Notification/Notification';
import { postResource } from '../../store/Fetch';
import { reportsApiUrl } from '../../store/settings/Local';

type Props = {
  feature: SGDEFeature;
  closeDialog: () => void;
  map: OpenLayersMap;
  showReportDialog?: (features: SGDEFeature[]) => void;
};

const CreateMeasurement = ({ feature, closeDialog, map }: Props) => {
  const { dispatch: notify } = useContext(NotificationContext);
  const onSave = async () => {
    try {
      await postResource(`${reportsApiUrl}/measurements/`, feature.getAreaMeasurement());
      map.reloadMeasurementsLayer(ReportType.Masuratoare);
      closeDialog();
      notify?.({ severity: 'success', message: 'Masuratoare creata cu succes' });
    } catch (ex) {
      notify?.({ severity: 'error', message: 'Eroare la crearea masuratorii' });
    }
  };

  return (
    <>
      <DialogTitle>Creaza masuratoare </DialogTitle>
      <DialogActions>
        <Button color="primary" onClick={onSave}>
          Salveaza
        </Button>
      </DialogActions>
    </>
  );
};

export default CreateMeasurement;
