import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import Dropzone, { FileWithPath } from 'react-dropzone';

import DeleteIcon from '@mui/icons-material/Delete';
import DescriptionIcon from '@mui/icons-material/Description';
import { IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';

import { DocumentLibrary } from '../../models/document-library.model';
import { Document } from '../../models/document.model';
import { deleteResource, getResource } from '../../store/Fetch';
import { reportsApiUrl } from '../../store/settings/Local';

type Props = {
  name: string;

  documentLibraryId: number;

  readonly: boolean;

  reportFieldId: number;

  onDocumentsChange: (documentLibrary: DocumentLibrary) => void;
};

const Documents = ({ name, documentLibraryId, readonly, onDocumentsChange }: Props) => {
  const [filesToUpload, setFilesToUpload] = useState<(File | FileWithPath)[]>([]);
  const [documentLibrary, setDocumentLibrary] = useState<Document[]>([]);

  const files = filesToUpload.map((file: FileWithPath) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));

  const onDrop = (files: File[] | FileList | null) => {
    if (!files) return;
    const newFilesToUpload = [...filesToUpload, ...Array.from(files)];
    setFilesToUpload(newFilesToUpload);
    onDocumentsChange &&
      onDocumentsChange({
        files: newFilesToUpload,
        documentLibraryId,
      });
  };

  const onDeleteDocument = useCallback(
    async (document: Document) => {
      await deleteResource(`${reportsApiUrl}/documents/${document.id}`);
      setDocumentLibrary(prev => prev.filter(d => d.id !== document.id));
    },
    [setDocumentLibrary]
  );

  const getDocumentLibrary = async () => {
    const libraryItems = await getResource(`${reportsApiUrl}/documents/${documentLibraryId}`);
    setDocumentLibrary(libraryItems);
  };

  const openDocument = (document: Document) => {
    window.open(`${reportsApiUrl}/documents/${document.id}/download`, '_blank')?.focus();
  };

  useEffect(() => {
    if (documentLibraryId) {
      getDocumentLibrary();
    }

    onDocumentsChange?.({
      files: [],
      documentLibraryId,
    });
  }, [documentLibraryId]);

  const renderDocumentLibrary = useCallback(() => {
    return (
      <List aria-label="documente incarcate">
        {documentLibrary.map(dl => (
          <ListItem
            key={dl.id}
            secondaryAction={
              <IconButton aria-label="sterge" onClick={() => onDeleteDocument(dl)}>
                <DeleteIcon />
              </IconButton>
            }
          >
            <ListItemButton onClick={() => openDocument(dl)}>
              <ListItemIcon>
                <DescriptionIcon />
              </ListItemIcon>
              <ListItemText primary={dl.name} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    );
  }, [documentLibrary]);

  return (
    <div style={{ width: '100%' }}>
      <h4>{name}</h4>
      {renderDocumentLibrary()}
      {!readonly && (
        <Dropzone onDrop={acceptedFiles => onDrop(acceptedFiles)}>
          {({ getRootProps, getInputProps }) => (
            <section className="container">
              <div
                style={{
                  height: '200px',
                  borderColor: '#00000052',
                  borderStyle: 'dashed',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  cursor: 'pointer',
                  borderRadius: '8px',
                  marginTop: '10px',
                }}
                {...getRootProps({ className: 'dropzone' })}
              >
                <input
                  onChange={(event: ChangeEvent<HTMLInputElement>) => onDrop(event.currentTarget.files)}
                  {...getInputProps()}
                />
                <p style={{ textAlign: 'center' }}>Trageti fisiere aici sau dati click pentru a adauga documente</p>
              </div>
              <aside>
                <h5>Documente ce vor fi incarcate:</h5>
                <ul>{files}</ul>
              </aside>
            </section>
          )}
        </Dropzone>
      )}
    </div>
  );
};

export default Documents;
