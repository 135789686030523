import { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import AddLinkIcon from '@mui/icons-material/AddLink';
import CachedIcon from '@mui/icons-material/Cached';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import LinkOffIcon from '@mui/icons-material/LinkOff';
import {
  Alert,
  Checkbox,
  DialogActions,
  DialogContent,
  DialogTitle,
  ListItem,
  ListItemButton,
  ListItemText,
} from '@mui/material';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItemIcon from '@mui/material/ListItemIcon';
import { groupBy } from 'lodash';
import { ReportType, ReportTypesAsValues } from '../../models/ReportType';
import OpenLayersMap from '../../models/map/open-layers-map.model';
import LightReport from '../../models/report-light.model';
import ConfirmationButton from '../../primitives/Button/ConfirmationButton';
import { NotificationContext } from '../../primitives/Notification/Notification';
import { useDeleteMeasurements, useTryLinkMeasurementsAndCreateReport } from '../../store/slices/measurementsApi';
import { useDeleteReportFields, useReportsWithParent } from '../../store/slices/reportsApi';
import CreateCropRotation from '../CropRotation/CreateCropRotation';
import EditDialog from './EditDialog';

type Props = {
  selectedReports: LightReport[];
  closeDialog: () => void;
  map: OpenLayersMap;
};

type ReportTypeGroupedCount = {
  reportTypeId: number;
  count: number;
};

const MultipleReportSelection = ({ selectedReports, closeDialog, map }: Props) => {
  const [reportTypesGroupedCount, setReportTypesGroupedCount] = useState<ReportTypeGroupedCount[]>();
  const [selectedTypes, setSelectedTypes] = useState<number[]>([]);
  const [deleteMeasurements, { isSuccess: areaMeasurementsDeleted }] = useDeleteMeasurements();
  const [deleteReportFields, { isSuccess: areaReportsFieldsDeleted, error: reportFieldsDeleteError }] =
    useDeleteReportFields();
  const [tryLinkMeasurementsAndCreateReport, { isSuccess: linkedMeasurementsAndCreatedReports }] =
    useTryLinkMeasurementsAndCreateReport();
  const { dispatch: notify } = useContext(NotificationContext);
  const [reportsIds, setReportsIds] = useState<number[]>([]);
  const { data: reports } = useReportsWithParent(reportsIds);
  const [isEditing, setIsEditing] = useState(false);
  const [isGenerateCropRotation, setIsGenerateCropRotation] = useState(false);

  useEffect(() => {
    const reportsGroupedByType = groupBy(selectedReports, 'reportTypeId');
    const countOfTypes = Object.keys(reportsGroupedByType).reduce<ReportTypeGroupedCount[]>((acc, curr) => {
      return [
        ...acc,
        {
          reportTypeId: Number(curr),
          count: reportsGroupedByType[curr].length,
        } as ReportTypeGroupedCount,
      ];
    }, [] as ReportTypeGroupedCount[]);

    setReportTypesGroupedCount(countOfTypes);
    if (countOfTypes.length === 1) {
      handleReportTypeToggle(countOfTypes.at(0)?.reportTypeId);
    }
  }, []);

  useEffect(() => {
    if (areaMeasurementsDeleted) {
      map.clearSelection();
      map.reloadAllMeasurementsLayers();
      closeDialog();
    }

    if (areaReportsFieldsDeleted) {
      map.clearSelection();
      map.reloadAllMeasurementsLayers();

      notify?.({ severity: 'success', message: 'Masuratoare dezlegata' });
      closeDialog();
    }

    if (linkedMeasurementsAndCreatedReports) {
      notify?.({ severity: 'success', message: 'Masuratori legate cu success' });
      map.reloadAllMeasurementsLayers();
      closeDialog();
    }

    if (reportFieldsDeleteError) {
      console.error(reportFieldsDeleteError);
      notify?.({ severity: 'error', message: 'Eroare la dezlegarea masuratorii' });
    }
  }, [areaMeasurementsDeleted, areaReportsFieldsDeleted, reportFieldsDeleteError]);

  const handleReportTypeToggle = (reportTypeId?: number) => () => {
    if (!reportTypeId) return;

    if (selectedTypes.includes(reportTypeId)) {
      selectedTypes.splice(selectedTypes.indexOf(reportTypeId), 1);
      setSelectedTypes([...selectedTypes]);
    } else {
      setSelectedTypes([...selectedTypes, reportTypeId]);
    }
  };

  const getReportTypeOptionText = (reportTypeId: ReportTypesAsValues, count: number) => {
    const indexOfReportType = Object.values(ReportType).indexOf(reportTypeId);
    return `${count} rapoarte de tipul '${Object.keys(ReportType)[indexOfReportType]}'`;
  };

  const getIdsOfAreaMeasurementsBySelectedReportTypes = () =>
    selectedReports.reduce(
      (prev, acc) => (selectedTypes.includes(acc.reportTypeId) ? [...prev, acc.areaMeasurementId] : prev),
      [] as number[]
    );

  const getIdsOfReportsBySelectedReportTypes = () =>
    selectedReports.reduce(
      (prev, acc) => (selectedTypes.includes(acc.reportTypeId) ? [...prev, acc.reportId] : prev),
      [] as number[]
    );

  const getIdsOfMeasurementsBySelectedReportTypes = () =>
    selectedReports.reduce(
      (prev, acc) => (selectedTypes.includes(acc.reportTypeId) ? [...prev, acc.measurementId] : prev),
      [] as number[]
    );

  const onDeleteMeasurements = () => {
    const idsOfMeasurementsToDelete = getIdsOfAreaMeasurementsBySelectedReportTypes();
    deleteMeasurements(idsOfMeasurementsToDelete);
  };

  const onUnlinkMeasurements = () => {
    const idsOfReportsToUnlink = getIdsOfReportsBySelectedReportTypes();
    deleteReportFields(idsOfReportsToUnlink);
  };

  const onTryLinkMeasurements = () => {
    tryLinkMeasurementsAndCreateReport({
      measurementIdsToLink: getIdsOfMeasurementsBySelectedReportTypes(),
      reportTypeToCreate: ReportType.Constructie,
    });
  };

  const onModifyReports = () => {
    const idsOfReportsToModify = getIdsOfReportsBySelectedReportTypes();
    setReportsIds(idsOfReportsToModify);
    setIsEditing(true);
  };

  const onGenerateCropRotation = () => {
    const idsOfReportsToModify = getIdsOfReportsBySelectedReportTypes();
    setReportsIds(idsOfReportsToModify);
    setIsGenerateCropRotation(true);
  };

  const canModify = selectedTypes.length === 1 && !selectedTypes.includes(ReportType.Masuratoare);

  const canDelete = selectedTypes.length > 0;

  const canUnlink = selectedTypes.length > 0 && !selectedTypes.includes(ReportType.Masuratoare);

  const canTryLink = selectedTypes.length === 1 && selectedTypes.includes(ReportType.Masuratoare);

  const canDoCropRotation = selectedTypes.length > 0 && selectedTypes.includes(ReportType.Parcela);

  const areOptionsVisible = useMemo(() => !isEditing && !isGenerateCropRotation, [isEditing, isGenerateCropRotation]);

  const getSelectedFeatures = useCallback(() => {
    const reportIds = reports?.map(r => r.id);
    return selectedReports.filter(sr => reportIds?.includes(sr.reportId) && sr.feature).map(sr => sr.feature!);
  }, [reports, selectedReports]);

  return (
    <>
      {areOptionsVisible && (
        <>
          <DialogTitle>Selectare rapoarte multiple</DialogTitle>
          <DialogContent>
            {selectedTypes.length === 0 && (
              <Alert severity="info">Pentru a vedea actiunile disponibile selectati minim un tip de raport</Alert>
            )}
            <List>
              {reportTypesGroupedCount?.map(rtg => {
                return (
                  <ListItem disablePadding key={rtg.reportTypeId}>
                    <ListItemButton onClick={handleReportTypeToggle(rtg.reportTypeId)} dense>
                      <ListItemIcon>
                        <Checkbox
                          edge="start"
                          checked={selectedTypes.indexOf(rtg.reportTypeId) >= 0}
                          tabIndex={-1}
                          disableRipple
                        />
                      </ListItemIcon>
                      <ListItemText
                        id={`report-type-option${rtg.reportTypeId}`}
                        primary={getReportTypeOptionText(rtg.reportTypeId as ReportTypesAsValues, rtg.count)}
                      ></ListItemText>
                    </ListItemButton>
                  </ListItem>
                );
              })}
            </List>
          </DialogContent>
          <DialogActions>
            {canModify && (
              <Button startIcon={<EditIcon />} color="secondary" onClick={onModifyReports}>
                Modifica
              </Button>
            )}
            {canDelete && (
              <ConfirmationButton
                color="error"
                startIcon={<DeleteIcon />}
                onConfirm={onDeleteMeasurements}
                message="Sunteti sigur ca doriti sa stergeti masuratoarile?"
                title="Confirmare stergere"
              >
                Sterge
              </ConfirmationButton>
            )}
            {canTryLink && (
              <Button startIcon={<AddLinkIcon />} color="secondary" onClick={onTryLinkMeasurements}>
                Legare automata
              </Button>
            )}
            {canUnlink && (
              <ConfirmationButton
                startIcon={<LinkOffIcon />}
                onConfirm={onUnlinkMeasurements}
                color="error"
                title="Confirmare dezlegare"
                message={`Sunteti sigur ca vreti sa dezlegati fisele selectate de masuratori?`}
              >
                Dezleaga
              </ConfirmationButton>
            )}
            {canDoCropRotation && (
              <Button startIcon={<CachedIcon />} color="secondary" onClick={onGenerateCropRotation}>
                Genereaza asolament
              </Button>
            )}
          </DialogActions>
        </>
      )}
      {isEditing && reports && <EditDialog reports={reports} closeDialog={closeDialog} map={map} />}
      {isGenerateCropRotation && reports && (
        <CreateCropRotation reports={reports} features={getSelectedFeatures()} closeDialog={closeDialog} />
      )}
    </>
  );
};

export default MultipleReportSelection;
