import { SyntheticEvent, createContext, useReducer, useState } from 'react';

import { Alert, AlertColor, Snackbar } from '@mui/material';

type State = {
  state: any;

  dispatch?: (action: Action) => void;
};

type Action = {
  message: string;

  severity?: AlertColor;

  type?: AlertColor;
};

type Props = {
  children: React.ReactNode[] | React.ReactNode;
};

const initialState: State = {
  state: {},
};

const NotificationContext = createContext(initialState);
const { Provider } = NotificationContext;

const NotificationProvider = ({ children }: Props) => {
  const [message, setMessage] = useState('');
  const [open, setOpen] = useState(false);
  const [severity, setSeverity] = useState<AlertColor | undefined>(undefined);

  const [state, dispatch] = useReducer((state: any, action: Action) => {
    setMessage(action.message);
    setOpen(true);
    setSeverity(action.type || action.severity);
    return state;
  }, initialState);

  const handleClose = (_event: Event | SyntheticEvent<any, Event>) => {
    setOpen(false);
  };

  return (
    <Provider value={{ state, dispatch }}>
      {children}
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={open}
        autoHideDuration={5000}
        onClose={handleClose}
        message={message}
      >
        <Alert onClose={handleClose} severity={severity}>
          {message}
        </Alert>
      </Snackbar>
    </Provider>
  );
};

export { NotificationContext, NotificationProvider };
