import { ReactNode, createContext, createRef, useEffect, useReducer, useState } from 'react';

import { LinearProgress, Paper, Popper } from '@mui/material';
import Typography from '@mui/material/Typography';

type State = { show: boolean };
type ContextState = {
  state: State;
  dispatch?: (action: Action) => void;
};

const initialState: ContextState = {
  state: {
    show: false,
  },
};
const ProgressContext = createContext<ContextState>(initialState);
const { Provider } = ProgressContext;

const DEFAULT_LOADING_MESSAGE = 'Se incarca. Va rugam asteptati';

type Props = {
  children: ReactNode | ReactNode[];
};

type Action =
  | string
  | {
      message?: string;
      type?: 'show' | 'hide';
    };

const ProgressProvider = ({ children }: Props) => {
  const [show, setShow] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const [message, setMessage] = useState(DEFAULT_LOADING_MESSAGE);
  const wrapperRef = createRef<HTMLDivElement>();
  const [state, dispatch] = useReducer((state: State, action: Action) => {
    let actionType;
    if (typeof action === 'string') {
      setMessage(DEFAULT_LOADING_MESSAGE);
      actionType = action;
    } else {
      setMessage(action.message || DEFAULT_LOADING_MESSAGE);
      actionType = action.type;
    }

    switch (actionType) {
      case 'show':
        setShow(true);
        break;
      case 'hide':
        setShow(false);
        break;
      default:
        break;
    }

    return state;
  }, initialState.state);

  useEffect(() => {
    if (wrapperRef.current) {
      setAnchorEl(wrapperRef.current);
    }
  }, []);

  return (
    <Provider value={{ state, dispatch }}>
      <div style={{ position: 'absolute', left: '2vw', bottom: '2vh' }} ref={wrapperRef}>
        <Popper open={show} anchorEl={anchorEl} placement="top-end" style={{ zIndex: '1400' }}>
          <Paper>
            <LinearProgress style={{ width: '100%' }} />
            <Typography sx={{ p: 2 }}>{message}</Typography>
          </Paper>
        </Popper>
      </div>
      {children}
    </Provider>
  );
};

export { ProgressContext, ProgressProvider };
