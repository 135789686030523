/* eslint-disable */
import { Feature, MapBrowserEvent, Overlay } from "ol";
import ContextMenu from "ol-contextmenu";
import { CallbackObject } from "ol-contextmenu";
import Collection, { CollectionEvent } from "ol/Collection";
import { FeatureLike } from "ol/Feature";
import Map from "ol/Map";
import View from "ol/View";
import { Coordinate } from "ol/coordinate";
import BaseEvent from "ol/events/Event";
import {
  never,
  platformModifierKeyOnly,
  shiftKeyOnly,
} from "ol/events/condition";
import {
  Geometry,
  LineString,
  MultiPoint,
  Polygon,
  SimpleGeometry,
} from "ol/geom";
import { DragBox, Modify, Select, Translate, defaults } from "ol/interaction";
import Layer from "ol/layer/Layer";
import TileLayer from "ol/layer/Tile";
import Projection from "ol/proj/Projection";
import { BingMaps } from "ol/source";
import VectorSource from "ol/source/Vector";
import XYZ from "ol/source/XYZ";
import { Fill, Stroke, Style } from "ol/style";
import CircleStyle from "ol/style/Circle";
import { blobStorageUrl, reportsApiUrl } from "../../store/settings/Local";
import { LoadingMessage, getPolygonOverlayOnClick } from "../../utils/Polygon";
import { BackgroundStyleDTO } from "../DTO/styling/BackgroundStyleDTO.ts";
import { ReportType, ReportTypes } from "../ReportType";
import { getCustomStyle } from "../feature/feature-styles";
import SGDEFeature, { FeatureType } from "../feature/sgde-feature";
import {
  BackgroundTypes,
  MeasurementsLayersMap,
  StreetviewLayersMap,
} from "./open-layer/map-utils";
import MeasurementsLayer from "./open-layer/measurement-vector-layers";
import StreetviewLayer from "./open-layer/streetview-vector-layer";

export default class OpenLayersMap {
  private readonly _vectorSource: VectorSource<Geometry>;

  private readonly _overlay: Overlay;

  private readonly _select: Select;

  private readonly _ortophotoplanLayer: Layer;

  private readonly _measurementsLayersMap = new MeasurementsLayersMap();

  private readonly _streetviewLayersMap = new StreetviewLayersMap();

  private _dblClickHandler?: (features: SGDEFeature[]) => void;

  private _selectedFeaturesId: number[] = [];

  private _listener?: (type: string, feature: SGDEFeature) => void;

  backgroundMap = new window.Map<number, Style>();

  setListener(listener: (type: string, feature: SGDEFeature) => void) {
    this._listener = listener;
  }

  setZoom(zoom: number) {
    this.inner.getView().setZoom(zoom);
  }

  private readonly _map: Map;

  private _modify?: Modify;

  private _translate?: Translate;

  private _measurementsZoom: number = 12;

  private _useCustomStyles: boolean = true;

  private _bingLayer: TileLayer<BingMaps>;

  get map(): Map {
    return this._map;
  }

  get measurementsLayersMap(): MeasurementsLayersMap {
    return this._measurementsLayersMap;
  }

  get measurementsZoom() {
    return this._measurementsZoom;
  }

  set measurementsZoom(value: number) {
    this._measurementsZoom = value;
  }

  get vectorSource(): VectorSource<Geometry> {
    return this._vectorSource;
  }

  get inner(): Map {
    return this._map;
  }

  get useCustomStyles(): boolean {
    return this._useCustomStyles;
  }

  constructor(
    target: string,
    center: number[],
    zoom: number,
    projection: Projection,
    overlayContainerId: string = "popup",
    private overlayContentId: string = "popup-content",
    private adminActionsEnabled: boolean = true,
    isPublic: boolean = false,
  ) {
    const vectorSource = new VectorSource();
    this._vectorSource = vectorSource;
    const container = document.getElementById(overlayContainerId) || undefined;
    this._overlay = new Overlay({
      element: container,
      autoPan: false,
    });

    this._bingLayer = new TileLayer({
      visible: true,
      preload: Infinity,
      source: new BingMaps({
        key: "Ap1OL0WhN9r2TpNmkJ7Gql2M-FAiFpJnI_OZz8Pk5P4uPrtQlQwh59Zm8gtKK3BF",
        imagerySet: "Aerial",
        placeholderTiles: true,
      }),
      zIndex: 1,
    });

    this._map = new Map({
      target,
      interactions: defaults({ doubleClickZoom: false }),
      layers: [
        new TileLayer({
          source: new XYZ({
            url: "https://{a-c}.tile.openstreetmap.org/{z}/{x}/{y}.png",
          }),
          zIndex: 0,
        }),
      ],
      overlays: [this._overlay],
      view: new View({
        projection,
        center,
        zoom,
      }),
    });

    this._select = new Select({
      style: function (feature: FeatureLike) {
        let selectedStrokeWitdh = 2;
        if (feature.getGeometry()?.getType() === "LineString") {
          selectedStrokeWitdh = 6;
        }

        return new Style({
          fill: new Fill({
            color: "rgba(16, 156, 241, 0.5)",
          }),
          stroke: new Stroke({
            color: "rgba(0, 48, 255, 0.8)",
            width: selectedStrokeWitdh,
          }),
          zIndex: 10,
        });
      },
      multi: false,
      toggleCondition: this.adminActionsEnabled ? shiftKeyOnly : never,
    });

    const masuratoareLayer = new MeasurementsLayer(this, {
      reportType: ReportType.Masuratoare,
      sourceUrl: `${reportsApiUrl}/geojson/measurements`,
      isPublic,
      zIndex: 6,
    });

    this._measurementsLayersMap.set(ReportType.Masuratoare, masuratoareLayer);

    const imobileLayer = new MeasurementsLayer(this, {
      reportType: ReportType.Imobil,
      sourceUrl: `${reportsApiUrl}/geojson/measurements`,
      isPublic,
      zIndex: 2,
    });

    this._measurementsLayersMap.set(ReportType.Imobil, imobileLayer);

    const constructiiLayer = new MeasurementsLayer(this, {
      reportType: ReportType.Constructie,
      sourceUrl: `${reportsApiUrl}/geojson/measurements`,
      isPublic,
      zIndex: 3,
    });

    this._measurementsLayersMap.set(ReportType.Constructie, constructiiLayer);

    const patrimoniuLayer = new MeasurementsLayer(this, {
      reportType: ReportType.Patrimoniu,
      sourceUrl: `${reportsApiUrl}/geojson/measurements`,
      isPublic,
      zIndex: 2,
    });

    this._measurementsLayersMap.set(ReportType.Patrimoniu, patrimoniuLayer);

    const caminLayer = new MeasurementsLayer(this, {
      reportType: ReportType.Camin,
      sourceUrl: `${reportsApiUrl}/geojson/measurements`,
      isPublic,
      zIndex: 5,
    });

    this._measurementsLayersMap.set(ReportType.Camin, caminLayer);

    const conducteLayer = new MeasurementsLayer(this, {
      reportType: ReportType.Conducta,
      sourceUrl: `${reportsApiUrl}/geojson/measurements`,
      isPublic,
      zIndex: 4,
    });

    this._measurementsLayersMap.set(ReportType.Conducta, conducteLayer);

    const parcelaLayer = new MeasurementsLayer(this, {
      reportType: ReportType.Parcela,
      sourceUrl: `${reportsApiUrl}/geojson/measurements`,
      isPublic,
      zIndex: 4,
    });

    this._measurementsLayersMap.set(ReportType.Parcela, parcelaLayer);

    this._ortophotoplanLayer = new TileLayer({
      source: new XYZ({
        url: `${blobStorageUrl}/${window.location.hostname.replaceAll(".", "-")}/ortophotoplan/{z}/{x}/{y}.png`,
      }),
      zIndex: 1,
    });

    this._map.addEventListener("dblclick", (ev) => {
      const mapEvent = ev as MapBrowserEvent<any>;

      const featuresAtPixel = this._map.getFeaturesAtPixel(mapEvent.pixel);
      if (featuresAtPixel.length === 0) return;

      const topMostFeature = featuresAtPixel[0] as Feature<Geometry>;

      const selectedFeatures = this._select.getFeatures();
      if (selectedFeatures.getLength() > 1) {
        this._dblClickHandler?.([
          ...selectedFeatures.getArray().map((f) => new SGDEFeature(f)),
        ]);
        return;
      }

      selectedFeatures.clear();
      selectedFeatures.push(topMostFeature);
      this._dblClickHandler?.([new SGDEFeature(topMostFeature)]);
    });

    let currentlyViewedFeatureId = -1;
    let featureInfo: string | Record<string, string>[];

    const handlePointerMove = (ev: BaseEvent | Event) => {
      const mapEvent = ev as MapBrowserEvent<any>;
      let pixel = this._map.getPixelFromCoordinate(mapEvent.coordinate);

      const featuresAtPixel = this._map.getFeaturesAtPixel(mapEvent.pixel);
      if (featuresAtPixel.length === 0) {
        this._overlay.setPosition(undefined);
        return;
      }

      const firstFeature = featuresAtPixel[0] as Feature;
      const olFeature = new SGDEFeature(firstFeature);
      if (currentlyViewedFeatureId !== olFeature.areaMeasurementId) {
        this._overlay.setPosition(undefined);
        return;
      }

      this._overlay.setPosition(this._map.getCoordinateFromPixel(pixel));

      const mapSize = this._map.getSize();

      const infoWindowHeight = this._overlay.getElement()?.offsetHeight ?? 0;
      const infoWindowWidth = this._overlay.getElement()?.offsetWidth ?? 0;

      const infoWindowWidthOffset = infoWindowWidth ? infoWindowWidth / 2 : 0;

      // If the info window is too close to the top, move it down.
      const topOffset = 20;
      if (pixel[1] < infoWindowHeight) {
        pixel[1] += infoWindowHeight + topOffset;
      }

      // If the info window is too close to the left, move it right.
      if (pixel[0] < infoWindowWidthOffset) {
        pixel[0] += infoWindowWidthOffset;
      }

      // If the info window is too close to the right, move it left.
      if (mapSize) {
        const rightOffset = 50;
        if (
          this._map.getSize() &&
          pixel[0] > mapSize[0] - infoWindowWidthOffset
        ) {
          pixel[0] -= infoWindowWidthOffset + rightOffset;
        }
      }

      this._overlay.setPosition(this._map.getCoordinateFromPixel(pixel));
    };

    const handleMapEvent = async (ev: BaseEvent | Event) => {
      const mapEvent = ev as MapBrowserEvent<any>;
      const featuresAtPixel = this._map.getFeaturesAtPixel(mapEvent.pixel);
      if (featuresAtPixel.length === 0) {
        this._overlay.setPosition(undefined);
        currentlyViewedFeatureId = -1;
        return;
      }

      const firstFeature = featuresAtPixel[0] as Feature;
      const olFeature = new SGDEFeature(firstFeature);
      if (currentlyViewedFeatureId !== olFeature.areaMeasurementId) {
        currentlyViewedFeatureId = olFeature.areaMeasurementId;
        this.showTooltip(LoadingMessage);
        featureInfo = await getPolygonOverlayOnClick(olFeature);
      }

      if (!featureInfo || Object.keys(featureInfo).length === 0) {
        featureInfo = olFeature.toolTip ?? olFeature.get("hint") ?? "-";
      }

      this.showTooltip(featureInfo);
      let pixel = this._map.getPixelFromCoordinate(mapEvent.coordinate);
      this._overlay.setPosition(this._map.getCoordinateFromPixel(pixel));
    };

    this._map.addInteraction(this._select);

    const contextmenu = new ContextMenu({
      width: 170,
    });

    const editMeasurementMenuItem = {
      text: "Editeaza masuratoare",
      callback: (obj: CallbackObject, map: Map) => {
        const pixel = map.getPixelFromCoordinate(obj.coordinate);
        const featureAtPixel = map.getFeaturesAtPixel(pixel)[0];

        if (!featureAtPixel || !(featureAtPixel instanceof Feature)) return;
        featureAtPixel.get("editable");
        (featureAtPixel as Feature).set("editable", true);
        const geometry = featureAtPixel.getGeometry();
        if (geometry instanceof Polygon) {
          this._listener?.("start-edit", new SGDEFeature(featureAtPixel));
        }

        if (geometry instanceof LineString) {
          this._listener?.("start-edit", new SGDEFeature(featureAtPixel));
        }
      },
    };

    const saveEditedMeasurementMenuItem = {
      text: "Salveaza masuratoare",
      callback: (obj: CallbackObject, map: Map) => {
        const pixel = map.getPixelFromCoordinate(obj.coordinate);
        const featureAtPixel = map.getFeaturesAtPixel(pixel)[0];

        if (!featureAtPixel || !(featureAtPixel instanceof Feature)) return;
        featureAtPixel.get("editable");
        (featureAtPixel as Feature).set("editable", false);
        const geometry = featureAtPixel.getGeometry();
        if (geometry instanceof Polygon) {
          this._listener?.("save-edit", new SGDEFeature(featureAtPixel));
        }

        if (geometry instanceof LineString) {
          this._listener?.("save-edit", new SGDEFeature(featureAtPixel));
        }
      },
    };

    const map = this._map;
    contextmenu.on("beforeopen", function (evt) {
      const features = map.getFeaturesAtPixel(evt.pixel);
      const feature = features[0] as Feature;

      if (feature && adminActionsEnabled) {
        contextmenu.enable();
      } else {
        contextmenu.disable();
      }
    });

    contextmenu.on("open", function (evt) {
      const features = map.getFeaturesAtPixel(evt.pixel);
      const feature = features[0] as Feature;
      if (!feature || !adminActionsEnabled) {
        contextmenu.closeMenu();
        return;
      }

      const innerFeature = (feature.get("features") as Feature[])?.[0];
      const isFeatureEditable =
        feature.get("editable") || innerFeature?.get("editable");

      if (isFeatureEditable) {
        contextmenu.clear();
        contextmenu.push(saveEditedMeasurementMenuItem);
      } else {
        contextmenu.clear();
        contextmenu.push(editMeasurementMenuItem);
      }
    });

    document.addEventListener("long-press", function (e) {
      // stop the event from bubbling up
      e.preventDefault();
      const mouseEvent = e as CustomEvent;
      let ev = new MouseEvent("contextmenu", {
        clientX: mouseEvent.detail.clientX,
        clientY: mouseEvent.detail.clientY,
      });

      map.getViewport().dispatchEvent(ev);
    });

    this._map.addControl(contextmenu);
    contextmenu.clear();
    // when measurement is linked it's in selected state, when unselected openlayers returns it to default style
    // so we need to override this
    this._select.getFeatures().on(["remove"], (event: any) => {
      const collectionEvent = event as CollectionEvent<any>;
      const removedFeature = collectionEvent.element as Feature;

      const removedFeatureType = removedFeature.get("type");
      if (removedFeatureType === FeatureType.Measurement) {
        removedFeature?.setStyle(
          getCustomStyle(this, new SGDEFeature(removedFeature)),
        );
      }
    });
    this.checkAndEnableDragboxMultiselect();

    this._map.on("pointermove", handlePointerMove);
    this._map.on("click", handleMapEvent);
  }

  setDblClickHandler = (dblClickHandler: (features: SGDEFeature[]) => void) => {
    this._dblClickHandler = dblClickHandler;
  };

  reloadMeasurementsLayer(measurementType: ReportTypes): void {
    const measurementsLayer = this._measurementsLayersMap.get(measurementType);
    measurementsLayer?.getSource()?.refresh();
  }

  reloadAllMeasurementsLayers(): void {
    this._measurementsLayersMap.forEach(
      (measurementsLayer: MeasurementsLayer) =>
        measurementsLayer.getSource()?.refresh(),
    );
  }

  showTooltip(featureInfo: string | Record<string, string>[]) {
    const content = document.getElementById(this.overlayContentId);
    if (!content) {
      return;
    }

    if (featureInfo) {
      if (typeof featureInfo === "object") {
        content.innerHTML =
          '<p style="text-align:left">' +
          this.formatPublicInfoBox(featureInfo) +
          "</p>";
      } else {
        content.innerHTML = "<p>" + featureInfo + "</p>";
      }
    }
  }

  formatPublicInfoBox = (publicInfoBox: Record<string, string>[]): string => {
    if (!publicInfoBox) return "";
    let output = "";
    for (const key in publicInfoBox) {
      output += `<b>${key}</b>: ${publicInfoBox[key]} <br/>`;
    }
    return output;
  };

  setBackgroundOptions(newBackGroundOptions: BackgroundStyleDTO[]) {
    for (let backgroundOption of newBackGroundOptions) {
      if (!backgroundOption.color) {
        const canvas = document.createElement("canvas");
        const context = canvas.getContext("2d");

        if (!context) continue;
        let imageObj = new Image();
        const backgroundMap = this.backgroundMap;
        imageObj.onload = function () {
          let pattern = context.createPattern(imageObj, "repeat");
          if (!pattern) return;

          context.rect(0, 0, canvas.width, canvas.height);
          context.fillStyle = pattern;
          context.fill();
          backgroundMap.set(
            backgroundOption.id,
            new Style({
              fill: new Fill({
                color: pattern,
              }),
            }),
          );
        };

        imageObj.src = `${blobStorageUrl}/${window.location.hostname.replaceAll(".", "-")}/style/${
          backgroundOption.image
        }`;
      }

      if (!backgroundOption.image) {
        this.backgroundMap.set(
          backgroundOption.id,
          new Style({
            fill: new Fill({
              color: backgroundOption.color,
            }),
          }),
        );
      }
    }
  }

  checkAndEnableDragboxMultiselect() {
    if (!this.adminActionsEnabled) return;

    const selectedFeatures = this._select.getFeatures();

    const dragBox = new DragBox({
      condition: platformModifierKeyOnly,
    });

    const map = this._map;
    const layersMap = this._measurementsLayersMap;
    dragBox.on("boxend", function () {
      const extent = dragBox.getGeometry().getExtent();

      const layers = [...layersMap];
      const boxFeatures = layers
        .flatMap(([_, l]) => l.getSource()?.getFeaturesInExtent(extent))
        .filter(
          (f: Feature | undefined) =>
            f && f?.getGeometry()?.intersectsExtent(extent),
        ) as Feature<Geometry>[];

      const rotation = map.getView().getRotation();
      const oblique = rotation % (Math.PI / 2) !== 0;

      if (oblique) {
        const anchor = [0, 0];
        const geometry = dragBox.getGeometry().clone();
        geometry.rotate(-rotation, anchor);
        const extent = geometry.getExtent();
        boxFeatures.forEach(function (feature: Feature) {
          const geometry = feature?.getGeometry()?.clone();
          geometry?.rotate(-rotation, anchor);
          if (geometry?.intersectsExtent(extent)) {
            selectedFeatures.push(feature!);
          }
        });
      } else {
        selectedFeatures.extend(boxFeatures);
      }
    });

    this._map.addInteraction(dragBox);
  }

  removePolygons(ids: number[]): void {
    for (const id of ids) {
      const featureWithId = this._vectorSource.getFeatureById(id);
      if (!featureWithId) return;

      this.vectorSource.removeFeature(featureWithId);
    }
  }

  showBackgroundType(show: boolean, backgroundType: BackgroundTypes) {
    if (show) {
      this.inner.removeLayer(this._ortophotoplanLayer);
      this.inner.removeLayer(this._bingLayer);

      if (backgroundType === "bing") {
        this.inner.addLayer(this._bingLayer);
      } else if (backgroundType === "ortofotoplan") {
        this.inner.addLayer(this._ortophotoplanLayer);
      }
    } else {
      this.inner.removeLayer(this._ortophotoplanLayer);
      this.inner.removeLayer(this._bingLayer);
    }
  }

  showCustomStyles(show?: boolean) {
    this._useCustomStyles = show || false;
    this.inner.getLayers().forEach((layer) => {
      if (layer instanceof MeasurementsLayer) {
        const measurementsLayer = layer as MeasurementsLayer;
        const layerSource = measurementsLayer?.getSource();
        layerSource?.refresh();
      }
    });
  }

  showMeasurementLayers(reportTypeIds: ReportTypes[]) {
    this._measurementsLayersMap.forEach((l) => {
      this._map.removeLayer(l);
    });

    reportTypeIds?.forEach((rt) => {
      const measurementLayer = this._measurementsLayersMap.get(rt);

      if (measurementLayer) {
        this._map.addLayer(measurementLayer);
      }
    });
  }

  disableSelect() {
    this.map.removeInteraction(this._select);
  }

  setCenter([x, y]: [number, number]) {
    this.inner.getView().setCenter([x, y]);
    this.inner.getView().setZoom(10);
  }

  clearSelection(): void {
    this._select.getFeatures().clear();
  }

  addFeatureToSelection(reportTypeId: ReportTypes, id: number): void {
    const layer = this._measurementsLayersMap.get(reportTypeId);
    const layerSource = layer?.getSource();
    const feature = layerSource?.getFeatureById(id);
    if (feature) {
      const features = this._select.getFeatures();
      if (features.getArray().find((f) => f.getId() === feature.getId())) {
        return;
      }

      features.push(feature);
    } else {
      this._selectedFeaturesId.push(id);
    }
  }

  editFeature(feature: SGDEFeature) {
    this._modify = new Modify({
      features: new Collection([feature]),
    });

    this._translate = new Translate({
      features: new Collection([feature]),
    });

    feature.setStyle([
      new Style({
        stroke: new Stroke({
          color: "blue",
          width: 3,
        }),
        fill: new Fill({
          color: "rgba(0, 0, 255, 0.1)",
        }),
        zIndex: 100,
      }),
      new Style({
        image: new CircleStyle({
          radius: 5,
          fill: new Fill({
            color: "orange",
          }),
        }),
        geometry: function (feature) {
          // return the coordinates of the first ring of the polygon
          const coordinates = (
            feature.getGeometry() as SimpleGeometry
          ).getCoordinates()?.[0] as Coordinate[];
          return new MultiPoint(coordinates);
        },
        zIndex: 100,
      }),
    ]);

    this._map.addInteraction(this._modify);
    this._map.addInteraction(this._translate);
  }

  endEditFeature(): void {
    if (this._modify) {
      this._map.removeInteraction(this._modify);
    }

    if (this._translate) {
      this._map.removeInteraction(this._translate);
    }
  }

  removeFeature(feature: SGDEFeature): void {
    const layer = this._measurementsLayersMap.get(feature.get("reportTypeId"));
    if (layer) {
      layer.vectorSource.removeFeature(feature);
    }
  }

  checkIsFeatureSelected(featureId: number): boolean {
    return this._selectedFeaturesId.includes(featureId);
  }

  showStreeview(showStreetview: boolean | undefined, years: number[]) {
    if (!showStreetview) {
      this._streetviewLayersMap.forEach((sv) => this._map.removeLayer(sv));
      return;
    }

    this._streetviewLayersMap.forEach((l, _) => {
      this._map.removeLayer(l);
    });

    years.forEach((y) => {
      let yearLayer = this._streetviewLayersMap.get(y);
      if (!yearLayer) {
        yearLayer = new StreetviewLayer({
          sourceUrl: `${reportsApiUrl}/geojson/streetview`,
          year: y,
          zIndex: 7,
        });
      }

      this._streetviewLayersMap.set(y, yearLayer);
      this._map.addLayer(yearLayer);
    });
  }
}
