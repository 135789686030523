import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  accordionContainer: {
    marginTop: 10
  },
  accordionDetails: {
    gap: 10,
    display: 'flex',
    flexWrap: 'wrap'
  },
  accordionField: {
    flex: '0 0 24%'
  }
}));
