import { SetState, StateCreator } from 'zustand';

import { GpsCoordinate } from '../../models/coordinate.model';
import MapType from '../../models/settings/map-type.model';
import { GlobalState } from '../useStore.ts';

export interface MapSettingsState {
  viewSettings: {
    mapBounds: number[][];
    extent: number[];
    mapZoom: number;
    editing: boolean;
    mapTypes: MapType[];
  };
  setEditing: (editing: boolean) => void;
  setMapTypes: (mapTypes: MapType[]) => void;
  setViewSettings: (mapZoom: number, bounds: GpsCoordinate[]) => void;
  setViewSettingsWithExtent: (mapZoom: number, extent: number[]) => void;
  setZoom: (mapZoom: number) => void;
  reset: () => void;
}

const createMapSettingsSlice: StateCreator<MapSettingsState, SetState<GlobalState>> = (set, get) => ({
  viewSettings: {
    mapBounds: [[]],
    extent: [],
    mapTypes: [],
    mapZoom: 16,
    editing: false,
  },
  setEditing: (editing: boolean) => {
    set({ viewSettings: { ...get().viewSettings, editing } });
  },
  setViewSettings: (mapZoom: number, bounds: GpsCoordinate[]) => {
    set({ viewSettings: { ...get().viewSettings, mapZoom, mapBounds: bounds.map(c => [c.lng, c.lat]) } });
  },
  setMapTypes: (mapTypes: MapType[]) => {
    set({ viewSettings: { ...get().viewSettings, mapTypes } });
  },
  setViewSettingsWithExtent: (mapZoom, extent) => {
    const southWest = [extent[0], extent[1]];
    const northEast = [extent[2], extent[3]];
    const southEast = [northEast[0], southWest[1]];
    const northWest = [southWest[0], northEast[1]];
    const mapBounds = [southWest, southEast, northEast, northWest];
    set({ viewSettings: { ...get().viewSettings, mapZoom, mapBounds } });
  },
  setZoom(mapZoom: number) {
    set({ viewSettings: { ...get().viewSettings, mapZoom } });
  },
  reset: () => {
    set({ viewSettings: { ...get().viewSettings } });
  },
});

export default createMapSettingsSlice;
