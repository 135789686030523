import { Inventory } from '../../models/inventory.model';

import { sgdeApi } from '../index';

const inventoryApi = sgdeApi.enhanceEndpoints({ addTagTypes: ['Inventory'] }).injectEndpoints({
  endpoints: build => ({
    createInventory: build.mutation<void, Inventory>({
      query: inventory => ({ url: `/inventory/${inventory.inventoryId}/items`, method: 'PUT', body: inventory.items }),
      invalidatesTags: ['Inventory'],
    }),
  }),
});

export const { useCreateInventoryMutation: useCreateInventory } = inventoryApi;
