import { Extent } from "ol/extent";
import { Style } from "ol/style";
import { ReportTypes } from "../../ReportType.ts";
import MeasurementsLayer from "./measurement-vector-layers";
import StreetviewLayer from "./streetview-vector-layer";

type MapBounds = number[][];

type ReportTypeStyles = Partial<{
  [key in ReportTypes]: Style;
}>;

type BackgroundTypes = "ortofotoplan" | "bing";

class MeasurementsLayersMap extends Map<ReportTypes, MeasurementsLayer> {
  constructor() {
    super();
  }
}

class StreetviewLayersMap extends Map<number, StreetviewLayer> {
  constructor() {
    super();
  }
}

const mapBoundsFromExtent = (extent: Extent): MapBounds => {
  const southWest = [extent[0], extent[1]];
  const northEast = [extent[2], extent[3]];
  const southEast = [northEast[0], southWest[1]];
  const northWest = [southWest[0], northEast[1]];
  const mapBounds = [southWest, southEast, northEast, northWest];
  return mapBounds;
};

export type { MapBounds, ReportTypeStyles, BackgroundTypes };
export { MeasurementsLayersMap, StreetviewLayersMap, mapBoundsFromExtent };
