import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  formGroup: {
    display: 'flex',
    flexDirection: 'column'
  },
  formItem: {
    gap: 10,
    display: 'flex'
  }
}));
