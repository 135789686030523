import { SetState, StateCreator } from 'zustand';
import { ReportType } from '../../../models/ReportType';
import { AreaMeasurement } from '../../../models/measurement.model';
import { postResource } from '../../Fetch.ts';
import { reportsApiUrl } from '../../settings/Local.ts';
import { GlobalState } from '../../useStore.ts';

export interface SewerMeasurementsState {
  sewerMeasurements: AreaMeasurement[];

  latestSewerMeasurements: AreaMeasurement[];

  getSewerMeasurements: (bounds?: number[][], customGeometry?: boolean, skip?: number, publicPageName?: string) => void;

  cleanSewerMeasurements: () => void;
}

const createSewerMeasurementsSlice: StateCreator<SewerMeasurementsState, SetState<GlobalState>> = (set, get) => ({
  sewerMeasurements: [],
  latestSewerMeasurements: [],
  getSewerMeasurements: async (bounds, customGeometry, skip = 0, publicPageName = '') => {
    const { measurements: latestSewerMeasurements, moreAvailable } = await postResource(
      `${reportsApiUrl}/constructii/measurements?reportTypes=${ReportType.Camin}&skip=${skip}&take=1000&customGeometry=${customGeometry}&publicLink=${publicPageName}`,
      bounds
    );

    const sewerMeasurements = [...get().sewerMeasurements, ...latestSewerMeasurements];
    set({ sewerMeasurements });
    set({ latestSewerMeasurements });
    const newSkip = skip + 1000;
    if (moreAvailable) {
      await get().getSewerMeasurements(bounds, customGeometry, newSkip, publicPageName);
    }
  },
  cleanSewerMeasurements: () => {
    set({ latestSewerMeasurements: [] });
  },
});

export default createSewerMeasurementsSlice;
