import { useCallback, useContext, useEffect, useState } from 'react';

import AddBoxIcon from '@mui/icons-material/AddBox';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  SelectChangeEvent,
} from '@mui/material';

import { SkipToken, skipToken } from '@reduxjs/toolkit/query';

import { ReportType, ReportTypes } from '../../models/ReportType';
import SGDEFeature from '../../models/feature/sgde-feature';
import OpenLayersMap from '../../models/map/open-layers-map.model';
import { Report } from '../../models/report.model';
import ConfirmationButton from '../../primitives/Button/ConfirmationButton';
import Dialog from '../../primitives/Modal/Dialog';
import { ProgressContext } from '../../primitives/Progress/Progress';
import { postResource } from '../../store/Fetch';
import { reportsApiUrl } from '../../store/settings/Local';
import { useMeasurementById } from '../../store/slices/measurementsApi';
import CreateBuilding from '../Building/CreateBuilding';
import CreateCamin from '../Camin/CreateCamin';
import CreateConducta from '../Conducta/CreateConducta';
import CreateParcel from '../Parcel/CreateParcel';
import CreatePatrimoniu from '../Patrimoniu/CreatePatrimoniu';
import SearchReport from '../SearchReport/SearchReport';
import CreateTerrain from '../Terrain/CreateTerrain';

type Props = {
  map: OpenLayersMap;

  feature: SGDEFeature;

  closeDialog: () => void;
};

const BlankMeasurementLink = ({ map, feature, closeDialog }: Props) => {
  const [reportType, setReportType] = useState(1);
  const [report, setReport] = useState<Report>();
  const [showNewReportDialog, setShowNewReportDialog] = useState(false);
  const [existingMeasurementId, setExistingMeasurementId] = useState<string | SkipToken>(skipToken);
  const { data: existingMeasurement } = useMeasurementById(existingMeasurementId);
  const { dispatch: progress } = useContext(ProgressContext);

  useEffect(() => {
    const measurementId = feature.getId();
    if (measurementId) {
      setExistingMeasurementId(measurementId.toString());
    }
  }, []);

  const onReportTypeChange = (event: SelectChangeEvent<number>) => {
    setReportType(Number(event.target.value));
  };

  const onSelectedReport = (selectedReport: Report) => {
    setReport(selectedReport);
  };

  const newReportCreate = () => {
    setShowNewReportDialog(true);
  };

  const deleteMeasurement = async () => {
    const result = await fetch(`${reportsApiUrl}/measurements/${feature.areaMeasurementId}`, {
      mode: 'cors',
      method: 'DELETE',
    });

    if (result.status === 204) {
      map.reloadMeasurementsLayer(ReportType.Masuratoare);
      closeDialog();
    }
  };

  const onSave = async () => {
    if (!report?.id || !report.reportFields) {
      return;
    }

    const areaMeasurement = feature.getAreaMeasurement();
    await postResource(
      `${reportsApiUrl}/measurements/${feature.measurementId}/report/${report.id}?area=${areaMeasurement.area}&centerLat=${areaMeasurement.lat}&centerLng=${areaMeasurement.lng}`
    );

    progress?.('hide');
    map.reloadMeasurementsLayer(report.reportTypeId as ReportTypes);
    map.reloadMeasurementsLayer(ReportType.Masuratoare);
    closeDialog();
  };

  const onNewReportClose = async () => {
    setShowNewReportDialog(false);
    closeDialog();
  };

  const switchCreateReportType = useCallback(() => {
    switch (reportType) {
      case 1:
        return (
          <CreateTerrain
            map={map}
            feature={feature}
            existingMeasurement={existingMeasurement}
            closeDialog={closeDialog}
          />
        );
      case 2:
        return (
          <CreateBuilding
            map={map}
            feature={feature}
            existingMeasurement={existingMeasurement}
            closeDialog={closeDialog}
          />
        );
      case 4:
        return (
          <CreatePatrimoniu
            map={map}
            feature={feature}
            existingMeasurement={existingMeasurement}
            closeDialog={closeDialog}
          />
        );
      case 5:
        return (
          <CreateCamin
            map={map}
            feature={feature}
            existingMeasurement={existingMeasurement}
            closeDialog={closeDialog}
          />
        );
      case 6:
        return (
          <CreateConducta
            map={map}
            feature={feature}
            existingMeasurement={existingMeasurement}
            closeDialog={closeDialog}
          />
        );
      case 7:
        return (
          <CreateParcel
            map={map}
            feature={feature}
            existingMeasurement={existingMeasurement}
            closeDialog={closeDialog}
          />
        );
      default:
        return <></>;
    }
  }, [reportType, feature, existingMeasurement]);

  return (
    <>
      <DialogTitle>Vizualizare Masuratoare</DialogTitle>
      <DialogContent style={{ paddingTop: '10px' }}>
        <Grid container>
          <Grid item sm={12}>
            <Grid container rowSpacing={2}>
              <Grid item sm={12}>
                <FormControl fullWidth>
                  <FormLabel>Tip raport</FormLabel>
                  <RadioGroup defaultValue={1} row onChange={onReportTypeChange} name="tip-raport">
                    <FormControlLabel value={1} control={<Radio />} label="Imobil" />
                    <FormControlLabel value={2} control={<Radio />} label="Constructie" />
                    <FormControlLabel value={4} control={<Radio />} label="Patrimoniu" />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item sm={12}>
                <SearchReport reportTypes={[reportType]} onSelectedReport={onSelectedReport}></SearchReport>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <ConfirmationButton
          color="error"
          key="delete"
          startIcon={<DeleteIcon></DeleteIcon>}
          onConfirm={deleteMeasurement}
          message="Sunteti sigur ca doriti sa stergeti masuratoarea?"
          title="Confirmare stergere"
        >
          Sterge
        </ConfirmationButton>
        <Button color="secondary" key="new" startIcon={<AddBoxIcon></AddBoxIcon>} onClick={newReportCreate}>
          Fisa noua
        </Button>
        <Button startIcon={<SaveIcon />} key="save" onClick={onSave} color="primary">
          Salveaza
        </Button>
      </DialogActions>
      <Dialog maxWidth="md" onClose={() => onNewReportClose()} open={showNewReportDialog}>
        {switchCreateReportType()}
      </Dialog>
    </>
  );
};

export default BlankMeasurementLink;
