import { ReportFieldRenderType } from '../../../models/ReportFieldRenderType';
import { DocumentLibrary } from '../../../models/document-library.model';
import { FormReportFieldDefinition } from '../../../models/form-report-field-definition.model';
import { Gallery } from '../../../models/gallery.model';
import { Inventory } from '../../../models/inventory.model';
import { ReportFieldGroup } from '../../../models/report-field-group.model';
import { DynamicField } from '../DynamicForm/DynamicField.tsx';

interface IProps {
  childMultipleFields: FormReportFieldDefinition[];
  allGroups: ReportFieldGroup[];
  readonly?: boolean;
  onChange?: (fieldDefinition: FormReportFieldDefinition) => void;
  onGalleryChanged?: (fieldId: number, gallery: Gallery) => void;
  onDocumentsChange?: (documentLibraryId: number, documents: DocumentLibrary) => void;
  onMultiYearChange?: (inventory: Inventory) => void;
}

export const MultipleLine = ({
  childMultipleFields,
  allGroups,
  readonly,
  onChange,
  onDocumentsChange,
  onMultiYearChange,
  onGalleryChanged,
}: IProps) => {
  return (
    <>
      {childMultipleFields.map(childField => (
        <DynamicField
          key={`dynamic-form-item-${childField.id}`}
          readonly={childField.renderType === ReportFieldRenderType.Readonly || readonly}
          field={childField}
          allFields={childMultipleFields}
          allGroups={allGroups}
          onChange={onChange}
          onDocumentsChange={onDocumentsChange}
          onGalleryChanged={onGalleryChanged}
          onMultiYearChange={onMultiYearChange}
        />
      ))}
    </>
  );
};
