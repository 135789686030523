import { Button, DialogActions, DialogContent, DialogTitle } from '@mui/material';

type Props = {
  title: string;

  message: string;

  confirm: () => void;
};

const ConfirmationDialog = ({ title, message, confirm }: Props) => {
  return (
    <>
      <DialogTitle>{title || 'Confirmare operatiu'}</DialogTitle>
      <DialogContent>
        <p>{message}</p>
      </DialogContent>
      <DialogActions>
        <Button color="error" onClick={confirm}>
          Confirma
        </Button>
      </DialogActions>
    </>
  );
};

export default ConfirmationDialog;
