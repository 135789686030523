import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  root: {
    maxWidth: 280,
    display: 'flex',
    gap: 24,
    flexDirection: 'column',
    '& button': {
      width: 'fit-content',
    },
  },
}));
