import { GpsCoordinate } from './coordinate.model';
import CustomCoordinate from './custom-coordinate.model';

export interface AreaMeasurement {
  id: number;
  area: number;
  zoomLevel: number;
  lat: number;
  lng: number;
  height: number;
  width: number;
  coordinatesArray: GpsCoordinate[][];
  customCoordinatesArray: CustomCoordinate[][];
  customProjection: number;
  parentMeasurementId?: number;
  parentReportId?: number;
  reportTypeId: number;
  houseNumber?: string;
  publicInfoBox?: PublicInfoBox;
  geometryType: GeometryType;
  isReportPristine: boolean;
  state?: number;
  type?: string;
  styleId?: number;
}

export enum GeometryType {
  LineString = 0,
  Polygon = 1
} 

export interface PublicInfoBox {
  [key: string]: string;
}

export type CreateAreaMeasurement = Omit<AreaMeasurement, 'id' | 'reportTypeId'>;
