import { ChangeEvent, useContext, useEffect, useState } from 'react';

import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { ExpandLess, ExpandMore, ImportExport } from '@mui/icons-material';
import AccountCircle from '@mui/icons-material/AccountCircle';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import MenuIcon from '@mui/icons-material/Menu';
import TableChartIcon from '@mui/icons-material/TableChart';
import { Box, Checkbox, Collapse, FormControlLabel, Link, ListItemButton, Switch } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import List from '@mui/material/List';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import DxfImport from '../../components/DxfImport/DxfImport';
import ProfileSettings from '../../components/ProfileSettings/ProfileSettings';
import { menuTitle } from '../../store/settings/Local';
import { useLogout } from '../../store/slices/authenticationApi';
import { MapSettingsState } from '../../store/slices/mapSettingsSlice';
import { SettingsState } from '../../store/slices/settingsSlice';
import { useStreetViewYears } from '../../store/slices/streeViewApi';
import { useCurrentUser } from '../../store/slices/usersApi';
import useStore from '../../store/useStore';
import ExportReports from '../ExportReports/ExportReports';
import { ModalContext } from '../Modal/ModalContext';
import PublicPage from '../Modal/PublicPage/PublicPage';
import Preferences from '../Preferences/Preferences';
import { useStyles } from './Menu.styles';
import MenuItemWithDialogAction from './MenuItemWithDialogAction';
import MenuSearch from './MenuSearch';

const SUPPORT_URL = 'https://sgde.ro/suport' as const;

function AppMenu() {
  const theme = useTheme();
  const { dispatch: modal } = useContext(ModalContext);
  const [expandAdmin, setExpandAdmin] = useState(false);
  const { data: streetViewYears } = useStreetViewYears();
  const setSettings = useStore((state: SettingsState) => state.setSettings);
  const saveSettings = useStore((state: SettingsState) => state.saveSettings);
  const setZoom = useStore((state: MapSettingsState) => state.setZoom);
  const settings = useStore((state: SettingsState) => state.settings);
  const [logout] = useLogout();
  const { instance } = useMsal();
  const isB2CUser = useIsAuthenticated();
  const classes = useStyles(theme);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>();
  const open = Boolean(anchorEl);
  const { data: currentUser } = useCurrentUser();
  const isSuperAdmin = currentUser?.roles.some(role => role.name === 'SuperAdmin');
  const isAdmin = isSuperAdmin || currentUser?.roles.some(role => role.name === 'Admin');

  useEffect(() => {
    if (anchorEl) {
      setExpandAdmin(false);
    }
  }, [anchorEl]);

  const openExportReports = () => {
    modal?.({
      type: 'open',
      title: 'Export Rapoarte',
      fullScreen: true,
      mainContent: <ExportReports />,
    });

    setAnchorEl(null);
  };

  const openProfile = () => {
    modal?.({
      type: 'open',
      title: 'Setari profil',
      mainContent: <ProfileSettings />,
      width: 'lg',
    });

    setAnchorEl(null);
  };

  const openPublicPageSettings = () => {
    modal?.({
      type: 'open',
      title: 'Pagina public',
      mainContent: <PublicPage />,
      width: 'lg',
    });

    setAnchorEl(null);
  };

  const openPreferences = () => {
    modal?.({
      type: 'open',
      title: 'Preferinte',
      mainContent: <Preferences></Preferences>,
    });

    setAnchorEl(null);
  };

  const handleShowStreetViewChange = (event: ChangeEvent<HTMLInputElement>) => {
    const showStreetView = event.target.checked;
    const newSettings = { ...settings, showStreetView };
    setSettings(newSettings);
    saveSettings();
  };

  const handleYearsChange = (event: SelectChangeEvent<any>) => {
    const options = event.target.value;
    const newSettings = { ...settings, streetViewYears: options };
    setSettings(newSettings);
    saveSettings();
  };

  return (
    <div>
      <AppBar position="static">
        <Toolbar>
          {(settings.publicPageName && (
            <>
              <MenuSearch setZoom={setZoom} setSettings={setSettings} settings={settings} modal={modal} />

              <Typography variant="h6" className="menu-title">
                {settings.publicPageTitle}
              </Typography>

              <Link href="https://sgde.ro" target="_blank" rel="noreferrer">
                <Box>
                  <img src="/logo.png" alt="SGDE Logo" width={80} />
                </Box>
              </Link>
            </>
          )) ||
            (currentUser && (
              <>
                {!currentUser.softDeleted && (
                  <>
                    <IconButton
                      color="inherit"
                      aria-label="open drawer"
                      onClick={() => setOpenDrawer(true)}
                      edge="start"
                      size="large"
                    >
                      <MenuIcon />
                    </IconButton>
                    <MenuSearch setZoom={setZoom} setSettings={setSettings} settings={settings} modal={modal} />
                    <Typography variant="h6" className="menu-title">
                      {menuTitle}
                    </Typography>
                  </>
                )}
                <IconButton
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={({ currentTarget }) => setAnchorEl(currentTarget)}
                  color="inherit"
                  size="large"
                  sx={{ mr: 0, ml: 'auto' }}
                >
                  <AccountCircle />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={open}
                  onClose={() => setAnchorEl(null)}
                >
                  <MenuItem onClick={openProfile}>Profil</MenuItem>
                  {!currentUser.softDeleted && (
                    <>
                      <MenuItem onClick={openPublicPageSettings}>Pagina public</MenuItem>
                      <MenuItem onClick={openPreferences}>Preferinte</MenuItem>
                      {settings && [
                        <MenuItem key={'PreferinteRapide'} className={classes.quickSettingsHeader}>
                          Preferinte rapide:
                        </MenuItem>,
                        <MenuItem key={'ShowStreetView'}>
                          <FormControlLabel
                            control={
                              <Switch
                                checked={!!settings.showStreetView}
                                onChange={handleShowStreetViewChange}
                                name="showStreetView"
                                color="primary"
                              />
                            }
                            label="Afiseaza street view"
                          />
                        </MenuItem>,
                        <MenuItem key={'StreetViewYears'}>
                          <FormControl style={{ width: '100%' }}>
                            <InputLabel>Afisare streetview</InputLabel>
                            <Select
                              fullWidth
                              multiple
                              value={settings.streetViewYears ?? []}
                              onChange={handleYearsChange}
                              renderValue={selected => selected.join(', ')}
                              input={<Input />}
                            >
                              {streetViewYears?.map(year => (
                                <MenuItem key={year} value={year}>
                                  <Checkbox checked={settings.streetViewYears?.indexOf(year) > -1} />
                                  <ListItemText primary={year} />
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </MenuItem>,
                      ]}
                      {isSuperAdmin && (
                        <MenuItem onClick={() => setExpandAdmin(value => !value)}>
                          Admin
                          {expandAdmin ? (
                            <ExpandLess sx={{ margin: 'auto 0 auto auto' }} />
                          ) : (
                            <ExpandMore sx={{ margin: 'auto 0 auto auto' }} />
                          )}
                        </MenuItem>
                      )}
                      <Collapse component="li" in={expandAdmin} timeout="auto" unmountOnExit>
                        <List sx={{ pl: 2 }}>
                          <ListItemButton onClick={() => window.open('/ReportTypes', '_blank')}>
                            Report types
                          </ListItemButton>
                          <ListItemButton onClick={() => window.open('/ReportFieldGroups', '_blank')}>
                            Report field groups
                          </ListItemButton>
                          <ListItemButton onClick={() => window.open('/ReportFieldTypes', '_blank')}>
                            Report field types
                          </ListItemButton>
                          <ListItemButton onClick={() => window.open('/ReportFieldDefinitions', '_blank')}>
                            Report field definitions
                          </ListItemButton>
                          <ListItemButton onClick={() => window.open('/ReportFieldKeyValuePairs', '_blank')}>
                            Report field key/value pairs
                          </ListItemButton>
                        </List>
                      </Collapse>
                    </>
                  )}
                  <Divider variant="middle" style={{ marginTop: 4, marginBottom: 4 }} />
                  <MenuItem onClick={() => window.open(SUPPORT_URL, '_blank')}>Suport</MenuItem>
                  <Divider variant="middle" style={{ marginTop: 4, marginBottom: 4 }} />
                  <MenuItem
                    onClick={() => {
                      setAnchorEl(null);
                      isB2CUser ? instance.logout() : logout();
                    }}
                  >
                    Logout
                  </MenuItem>
                </Menu>
              </>
            ))}
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={openDrawer}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={() => setOpenDrawer(false)} size="large">
            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </div>
        <Divider />
        <List>
          <ListItemButton key={'Reports'}>
            <ListItemIcon>
              <TableChartIcon />
            </ListItemIcon>
            <ListItemText onClick={openExportReports}>Rapoarte</ListItemText>
          </ListItemButton>
          {isAdmin && (
            <MenuItemWithDialogAction
              key={'DxfImport'}
              icon={<ImportExport></ImportExport>}
              toOpen={closeDialog => <DxfImport closeDialog={closeDialog}></DxfImport>}
            >
              <>Import DXF</>
            </MenuItemWithDialogAction>
          )}
        </List>
      </Drawer>
    </div>
  );
}

export default AppMenu;
