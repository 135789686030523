import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { ColorResult, SketchPicker } from 'react-color';

import AddIcon from '@mui/icons-material/Add';
import SaveIcon from '@mui/icons-material/Save';
import {
  Autocomplete,
  Box,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormLabel,
  IconButton,
  Radio,
  RadioGroup,
  TextField,
} from '@mui/material';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Input from '@mui/material/Input';

import { BackgroundStyleDTO } from '../../../models/DTO/styling/BackgroundStyleDTO';
import Dialog from '../../../primitives/Modal/Dialog';
import { blobStorageUrl } from '../../../store/settings/Local';
import {
  useCreateBackgroundStyleColor,
  useCreateBackgroundStyleImage,
  useGetBackgroundStyles,
} from '../../../store/slices/backgroundStylesApi';

type Props = {
  onChange: (event: { target: { value: string } }) => void;
  styleId: number;
  disabled: boolean;
};
export const BackgroundStyleField = ({ onChange, styleId, disabled }: Props) => {
  const [selectedValue, setSelectedValue] = useState<BackgroundStyleDTO>();
  const [showAddOptionDialog, setShowAddOptionDialog] = useState<boolean>(false);
  const [color, setColor] = useState<string>();
  const [image, setImage] = useState<File>();
  const [style, setStyle] = useState<string>('culoare');
  const [styleName, setStyleName] = useState<string>();

  const [createBackgroundStyleImage, { isSuccess: isImageSuccess }] = useCreateBackgroundStyleImage();
  const [createBackgroundStyleColor, { isSuccess: isColorSuccess }] = useCreateBackgroundStyleColor();
  const { data: backgroundStyles } = useGetBackgroundStyles();

  useEffect(() => {
    if (isImageSuccess || isColorSuccess) {
      closeDialog();
    }
  }, [isImageSuccess, isColorSuccess]);

  useEffect(() => {
    const selectedStyle = backgroundStyles?.find(b => b.id === styleId);
    if (!selectedStyle) return;

    setSelectedValue(selectedStyle);
  }, [backgroundStyles, styleId]);

  const onAddOptionClick = () => {
    setShowAddOptionDialog(true);
  };

  const closeDialog = () => {
    setShowAddOptionDialog(false);
  };

  const onSaveOption = () => {
    if (style === 'imagine') {
      createBackgroundStyleImage({
        name: styleName!,
        imageBlob: image!,
      });
    }

    if (style === 'culoare') {
      createBackgroundStyleColor({
        name: styleName!,
        color,
      });
    }
  };

  const onStyleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setStyle((event.target as HTMLInputElement).value);
  };

  const onReactColorChange = (color: ColorResult) => {
    setColor(color.hex);
  };

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setImage(e.target.files[0]);
    }
  };

  const onNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    setStyleName(e.target.value);
  };

  const canSave = useMemo(() => {
    if (style === 'culoare') {
      return color && styleName;
    }

    if (style === 'imagine') {
      return image && styleName;
    }

    return false;
  }, [style, color, image, styleName]);

  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        {selectedValue?.id ? (
          <Autocomplete
            disabled={disabled}
            options={backgroundStyles!}
            getOptionLabel={option => option?.name}
            sx={{ width: 300 }}
            onChange={(_, newValue) => {
              newValue && setSelectedValue(newValue!);
              if (newValue) {
                onChange({ target: { value: newValue!.id.toString() } });
              }
            }}
            value={selectedValue}
            renderOption={(props, option) => (
              <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                {option?.image && (
                  <img
                    loading="lazy"
                    width="20"
                    src={`${blobStorageUrl}/${window.location.hostname.replaceAll('.', '-')}/style/${option.image}`}
                    alt=""
                  />
                )}
                {option?.color && (
                  <div style={{ width: '20px', height: '20px', backgroundColor: `${option.color}` }}></div>
                )}
                {option?.name}
              </Box>
            )}
            renderInput={params => <TextField {...params} label="Subzonificare functionala" />}
          />
        ) : (
          <Autocomplete
            disabled={disabled}
            options={backgroundStyles!}
            getOptionLabel={option => option?.name}
            sx={{ width: 300 }}
            onChange={(_, newValue) => {
              newValue && setSelectedValue(newValue!);
              if (newValue) {
                onChange({ target: { value: newValue!.id.toString() } });
              }
            }}
            renderOption={(props, option) => (
              <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                {option?.image && (
                  <img
                    loading="lazy"
                    width="20"
                    src={`${blobStorageUrl}/${window.location.hostname.replaceAll('.', '-')}/style/${option.image}`}
                    alt=""
                  />
                )}
                {option?.color && (
                  <div style={{ width: '20px', height: '20px', backgroundColor: `${option.color}` }}></div>
                )}
                {option?.name}
              </Box>
            )}
            renderInput={params => <TextField {...params} label="Subzonificare functionala" />}
          />
        )}
        <IconButton color="primary" aria-label="Adauga optiune" onClick={onAddOptionClick}>
          <AddIcon />
        </IconButton>
      </div>
      <Dialog maxWidth="md" onClose={closeDialog} open={showAddOptionDialog}>
        <DialogTitle>Adaugare optiune</DialogTitle>
        <DialogContent>
          <TextField
            required
            onChange={onNameChange}
            id="outlined-required"
            label="Denumire optiune"
            placeholder="Ex: Intravilan"
          />
          <FormControl>
            <FormLabel>Stil</FormLabel>
            <RadioGroup
              aria-labelledby="Stil"
              defaultValue="culoare"
              name="stil"
              value={style}
              onChange={onStyleChange}
            >
              <FormControlLabel value="culoare" control={<Radio />} label="Culoare" />
              <FormControlLabel value="imagine" control={<Radio />} label="Imagine" />
            </RadioGroup>
          </FormControl>
          {style === 'culoare' && <SketchPicker onChange={onReactColorChange} color={color} />}
          {style === 'imagine' && (
            <>
              <Input type="file" onChange={handleFileChange} />
              <p>{image?.name}</p>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button disabled={!canSave} startIcon={<SaveIcon />} onClick={onSaveOption} color="primary">
            Salveaza
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
