import { Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useContext, useEffect, useRef, useState } from 'react';
import { reportsApiUrl } from '../../store/settings/Local';
import { ModalContext } from '../Modal/ModalContext';
import { NotificationContext } from '../Notification/Notification';

type Props = {
  imagePath?: string;

  imageName?: string;
};

const useStyles = makeStyles({
  panoramaEditorRoot: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
  },
  canvasContainer: {
    flex: 1,
  },
});

const PanoramaEditor = ({ imagePath, imageName }: Props) => {
  const classes = useStyles();
  const [canvas, setCanvas] = useState<HTMLCanvasElement>();
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const { dispatch: modal } = useContext(ModalContext);
  const { dispatch: notify } = useContext(NotificationContext);

  useEffect(() => {
    let lastx = 0;
    let lasty = 0;
    let mouseDown = false;
    modal?.({
      type: 'setWidth',
      width: 'lg',
    });

    const canvas = canvasRef.current;
    if (!canvas) return;

    setCanvas(canvas);
    const context = canvas.getContext('2d');
    const image = new Image();
    image.src = imagePath!;
    image.crossOrigin = '*';
    image.onload = () => {
      canvas.width = image.width;
      canvas.height = image.height;
      context?.drawImage(image, 0, 0);
    };

    canvas.addEventListener('mousedown', e => {
      lastx = e.offsetX;
      lasty = e.offsetY;
      mouseDown = true;
    });

    canvas.addEventListener('mouseup', () => {
      mouseDown = false;
    });

    canvas.addEventListener('mousemove', e => {
      if (!context) return;
      if (mouseDown) {
        context.beginPath();
        const width = e.offsetX - lastx;
        const height = e.offsetY - lasty;
        context.rect(lastx, lasty, width, height);
        context.globalAlpha = 1.0;
        context.filter = 'blur(1px)';
        context.fillStyle = 'gray';
        context.fill();
        context.globalAlpha = 1.0;
        context.filter = 'none';
      }
    });

    modal?.({
      type: 'actions',
      actions: [
        {
          content: (
            <Button onClick={onSavePicture} color="primary" key="panorama-save">
              Salveaza
            </Button>
          ),
        },
      ],
    });
  }, [canvas]);

  const onSavePicture = async () => {
    canvas?.toBlob(
      async blob => {
        const result = await fetch(`${reportsApiUrl}/streetview/${imageName}`, {
          method: 'PUT',
          body: blob,
        });

        if (result.status === 200) {
          notify?.({ type: 'success', message: 'Imagine actualizata cu success!' });
          modal?.({ type: 'close' });
        } else {
          notify?.({ type: 'error', message: 'Eroare la actualizarea imaginii!' });
        }
      },
      'image/jpeg',
      0.95
    );
  };

  return (
    <div className={classes.panoramaEditorRoot}>
      <div className={classes.canvasContainer}>
        <canvas style={{ cursor: 'crosshair' }} ref={canvasRef}></canvas>
      </div>
    </div>
  );
};

export default PanoramaEditor;
