import { useEffect, useState } from 'react';

import { Extent } from 'ol/extent';
import VectorLayer from 'ol/layer/Vector';
import { Projection } from 'ol/proj';
import { Vector } from 'ol/source';

import { Popup } from '../../components/Popup/Popup';
import SGDEFeature from '../../models/feature/sgde-feature';
import OpenLayersMap from '../../models/map/open-layers-map.model';

type Props = {
  id: string;

  extent: Extent;

  projection: Projection;

  features?: SGDEFeature[];
};

const PreviewMap = ({ id, extent, projection, features }: Props) => {
  const [map, setMap] = useState<OpenLayersMap>();

  useEffect(() => {
    const newMap = new OpenLayersMap(id, [0, 0], 12, projection, 'preview-popup', 'preview-popup-content');
    setMap(newMap);
    return () => {
      newMap.inner.setTarget(undefined);
    };
  }, [projection, id]);

  useEffect(() => {
    map?.inner.getView().fit(extent, {
      padding: [20, 20, 20, 20],
    });

    map?.showBackgroundType(true, 'bing');
    map?.disableSelect();

    const vectorSource = new Vector({
      features: features,
    });

    const vectorLayer = new VectorLayer({
      source: vectorSource,
      zIndex: 10,
    });

    map?.inner.addLayer(vectorLayer);
  }, [map, extent, features]);

  console.log(map);

  return (
    <>
      <Popup containerId="preview-popup" contentId="preview-popup-content"></Popup>
      <div style={{ height: '400px', flex: '1' }} id={id} className="map"></div>
    </>
  );
};

export default PreviewMap;
