import { AuthenticationResult } from '@azure/msal-browser';

import { loginRequest } from '../authConfig';
import { msalInstance } from '../main';

const fetchResource = async <T>(
  uri: string,
  { method, headers, content, signal }: { method: string; headers?: any; content?: T; signal?: AbortSignal }
) => {
  const canHaveContent = method === 'POST' || method === 'PUT' || method === 'PATCH';
  if (!canHaveContent && content) {
    throw Error('cant have body in request');
  }

  const jsonContent = JSON.stringify(content);
  const requestHeaders = {
    ...headers,
    Authorization: await getToken(),
  };

  if (canHaveContent) {
    requestHeaders['Content-Type'] = 'application/json';
  }

  const options: RequestInit = {
    method: method || 'GET',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      ...requestHeaders,
    },
    body: jsonContent,
    mode: 'cors',
    signal,
  };

  const response = await fetch(uri, options);

  if (response.redirected) {
    window.location.replace(response.url);
  }

  const contentType = response.headers.get('content-type');
  if (contentType && contentType.indexOf('application/json') !== -1) {
    return await response.json();
  } else {
    return response;
  }
};

const getToken = async (): Promise<string> => {
  const account = msalInstance.getActiveAccount();
  const response = !account
    ? ({} as AuthenticationResult)
    : await msalInstance.acquireTokenSilent({ ...loginRequest, account });

  // If we have a token set in state, let's assume that we should be passing it.
  if (response.accessToken) {
    return `Bearer ${response.accessToken}`;
  }

  return '';
};

const getResource = async (url: string) => {
  return await fetchResource(url, { method: 'GET' });
};

const postResource = async <T>(url: string, content?: T, signal?: AbortSignal) => {
  return await fetchResource(url, { method: 'POST', content, signal });
};

const putResource = async <T>(url: string, content: T) => {
  return await fetchResource(url, { method: 'PUT', content });
};

const deleteResource = async (url: string) => {
  return await fetchResource(url, { method: 'DELETE' });
};

export { getResource, postResource, putResource, deleteResource };
