import { ChangeEvent, useEffect, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import { Box, Grid, IconButton, TextField } from '@mui/material';

import { DateTime } from 'luxon';

import { InventoryItem } from '../../models/inventory-item.model';
import { Inventory } from '../../models/inventory.model';
import { getResource } from '../../store/Fetch';
import { reportsApiUrl } from '../../store/settings/Local';

type Props = {
  inventoryId: number;

  name: string;

  readonly: boolean;

  onChange: (inventory: Inventory) => void;
};

const MultiYearInput = ({ inventoryId, name, readonly, onChange }: Props) => {
  const [values, setValues] = useState([
    {
      value: '',
      date: DateTime.now(),
    },
  ]);

  useEffect(() => {
    if (inventoryId) {
      getExistingValues();
      return;
    }
  }, [inventoryId]);

  const onAddValue = () => {
    const arrayCopy = [
      ...values,
      {
        value: '',
        date: DateTime.now(),
      },
    ];
    setValues(arrayCopy);
    onChange &&
      onChange({
        items: arrayCopy,
        inventoryId,
      });
  };

  const getExistingValues = async () => {
    const existingValue = await getResource(`${reportsApiUrl}/inventory/${inventoryId}`);
    existingValue.inventoryItems.forEach((inventoryItem: InventoryItem) => {
      inventoryItem.date = DateTime.fromISO(inventoryItem.date.toString());
    });
    setValues(
      existingValue.inventoryItems || [
        {
          value: '',
          date: DateTime.now(),
        },
      ]
    );
  };

  const onValueChange = (valuePosition: number, event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const arrayCopy = [...values];
    const currentValue = arrayCopy[valuePosition];
    currentValue.value = event.target.value;
    setValues(arrayCopy);
    onChange &&
      onChange({
        items: arrayCopy,
        inventoryId,
      });
  };

  const onYearChange = (valuePosition: number, event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const arrayCopy = [...values];
    const currentValue = arrayCopy[valuePosition];
    currentValue.date = DateTime.fromObject({ year: Number(event.target.value) });
    setValues(arrayCopy);
    onChange &&
      onChange({
        items: arrayCopy,
        inventoryId,
      });
  };

  return (
    <Box style={{ width: '100%' }}>
      <Box>
        <h4>{name}</h4>
      </Box>
      <Grid container style={{ flexBasis: '100%' }}>
        <Grid container item xs={readonly ? 12 : 11}>
          {values.map((v, i) => (
            <Grid key={i} style={{ padding: '10px 0' }} spacing={1} container item xs={12}>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  disabled={readonly}
                  variant="outlined"
                  onChange={e => onValueChange(i, e)}
                  value={v.value}
                  label="Valoare"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  disabled={readonly}
                  variant="outlined"
                  onChange={e => onYearChange(i, e)}
                  value={v.date.year}
                  label="An"
                />
              </Grid>
            </Grid>
          ))}
        </Grid>
        {!readonly && (
          <Grid style={{ display: 'flex', alignItems: 'flex-start', paddingTop: '10px' }} item xs={1}>
            <IconButton
              onClick={() => onAddValue()}
              color="primary"
              aria-label="adauga valoare"
              component="span"
              size="large"
            >
              <AddIcon />
            </IconButton>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default MultiYearInput;
