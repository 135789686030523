import { useEffect, useState } from 'react';

import { ReportField } from '../../models/report-field.model';
import { Report } from '../../models/report.model';
import { sgdeApi } from '../index';

const reportsApi = sgdeApi
  .enhanceEndpoints({ addTagTypes: ['Reports', 'ReportFields', 'MultipleItems'] })
  .injectEndpoints({
    endpoints: build => ({
      getReports: build.query<Report[], number[]>({
        query: ids => ({ url: `/reports/${ids.join(',')}` }),
        providesTags: ['Reports'],
      }),
      createReport: build.mutation<Report, any>({
        query: report => ({ url: '/reports', method: 'POST', body: report }),
        invalidatesTags: ['Reports'],
      }),
      updateReport: build.mutation<void, Report>({
        query: report => ({ url: `/reports/${report.id}`, method: 'PATCH', body: report }),
        invalidatesTags: ['Reports'],
      }),
      createReportFields: build.mutation<void, { reportFields: ReportField[]; reportId: number }>({
        query: ({ reportFields, reportId }) => ({
          url: `/reports/${reportId}/fields`,
          method: 'PUT',
          body: reportFields,
        }),
        invalidatesTags: ['ReportFields', 'MultipleItems'],
      }),
      deleteReportFields: build.mutation<void, number[]>({
        query: reportIds => ({
          url: `/report/${reportIds.join(
            ','
          )}/fields?reportFieldDefinitionIds=1&reportFieldDefinitionIds=35&reportFieldDefinitionIds=36`,
          method: 'DELETE',
        }),
        invalidatesTags: ['ReportFields', 'MultipleItems'],
      }),
      getReportFieldsById: build.query<ReportField[], number>({
        query: id => `/report/${id}/fields`,
        providesTags: ['Reports'],
      }),
    }),
  });

export const {
  useGetReportsQuery: useReports,
  useCreateReportMutation: useCreateReport,
  useUpdateReportMutation: useUpdateReport,
  useCreateReportFieldsMutation: useCreateReportFields,
  useDeleteReportFieldsMutation: useDeleteReportFields,
  useGetReportFieldsByIdQuery: useReportFieldsById,
} = reportsApi;

export const useReportsWithParent = (ids: number[]) => {
  const { data: reports, ...props } = useReports(ids);
  const [mappedReports, setMappedReports] = useState(reports);
  const parentReportIds = reports?.filter(r => !!r.parentReportId).map(r => r.parentReportId!) ?? [];
  const { data: parents } = useReports(parentReportIds, { skip: !props.isSuccess || !parentReportIds?.length });

  useEffect(() => {
    if (reports?.length) {
      setMappedReports(reports);
    }
    if (parents?.length) {
      setMappedReports(
        reports =>
          reports?.map(report => ({ ...report, reportParent: parents.find(p => p.id === report.parentReportId) }))
      );
    }
  }, [reports, parents]);

  return {
    data: mappedReports,
    ...props,
  };
};
