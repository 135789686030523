import { useState } from "react";

import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Tab } from "@mui/material";

import MapPreferencesPane from "./MapPreferencesPane";

const Preferences = () => {
  const [value, setValue] = useState("1");

  const handleTabChange = (_: unknown, newValue: string) => {
    setValue(newValue);
  };

  return (
    <TabContext value={value}>
      <TabList
        indicatorColor="primary"
        textColor="primary"
        onChange={handleTabChange}
        aria-label="Preferinte"
      >
        <Tab label="Harta" value="1" />
      </TabList>
      <TabPanel value="1">
        <MapPreferencesPane></MapPreferencesPane>
      </TabPanel>
    </TabContext>
  );
};

export default Preferences;
