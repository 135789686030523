import {
  Checkbox,
  Chip,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
} from '@mui/material';

import { useState } from 'react';
import { UserDTO } from '../../models/DTO/users/userDTO';
import { useRoles } from '../../store/slices/authorizationApi';
import {
  useAddUserRole,
  useDeleteUsers,
  useRecoverUsers,
  useRemoveUserRole,
  useUsers,
} from '../../store/slices/usersApi';
import { UsersTableHead } from './UsersTableHead.tsx';
import { UsersTableToolbar } from './UsersTableToolabar.tsx';

type Order = 'asc' | 'desc';

export const UsersTable = () => {
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<keyof UserDTO>('username');
  const [selected, setSelected] = useState<readonly number[]>([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const { data: users } = useUsers();
  const { data: roles } = useRoles();
  const [recoverUsers] = useRecoverUsers();
  const [deleteUsers] = useDeleteUsers();
  const [addUserRole] = useAddUserRole();
  const [removeUserRole] = useRemoveUserRole();

  const handleRequestSort = (property: keyof UserDTO) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const onSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = users?.map(u => u.id) || [];
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const onSelect = (id: number) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected: readonly number[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }

    setSelected(newSelected);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - (users?.length ?? 0)) : 0;

  return (
    <>
      <UsersTableToolbar selected={selected} />
      <TableContainer>
        <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size="medium">
          <UsersTableHead
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onSelectAll={onSelectAll}
            onSort={handleRequestSort}
            rowCount={users?.length ?? 0}
          />
          <TableBody>
            {stableSort(users || [], getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map(user => (
                <TableRow hover key={user.id} selected={selected.includes(user.id)}>
                  <TableCell padding="checkbox">
                    <Checkbox color="primary" checked={selected.includes(user.id)} onClick={() => onSelect(user.id)} />
                  </TableCell>
                  <TableCell>{user.id}</TableCell>
                  <TableCell>{user.username}</TableCell>
                  <TableCell>{user.email}</TableCell>
                  <TableCell>
                    {roles?.map(role => (
                      <Chip
                        key={role.id}
                        sx={{ margin: '4px' }}
                        label={role.name}
                        disabled={role.softDeleted}
                        onClick={() => {
                          const data = { id: user.id, roleId: role.id };
                          if (user.roles.some(r => r.id === role.id)) {
                            removeUserRole(data);
                          } else {
                            addUserRole(data);
                          }
                        }}
                        variant={user.roles.some(r => r.id === role.id) ? 'filled' : 'outlined'}
                      />
                    ))}
                  </TableCell>
                  <TableCell>
                    <Switch
                      checked={!user.softDeleted}
                      onChange={({ target }) => (target.checked ? recoverUsers([user.id]) : deleteUsers([user.id]))}
                    />
                  </TableCell>
                </TableRow>
              ))}
            {emptyRows > 0 && (
              <TableRow style={{ height: 71 * emptyRows }}>
                <TableCell colSpan={5} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={users?.length ?? 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={(_, page) => setPage(page)}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
};

const stableSort = <T extends UserDTO>(array: readonly T[], comparator: (a: T, b: T) => number) => {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
};

const getComparator = <Key extends keyof UserDTO>(order: Order, orderBy: Key): ((a: UserDTO, b: UserDTO) => number) =>
  order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);

const descendingComparator = (a: UserDTO, b: UserDTO, key: keyof UserDTO) =>
  typeof a[key] === 'string'
    ? b[key]?.toString()?.localeCompare(a[key].toString(), undefined, { sensitivity: 'accent' })
    : b[key] < a[key]
      ? -1
      : b[key] > a[key]
        ? 1
        : 0;
