import { ChangeEvent, useCallback, useEffect, useState } from 'react';

import TextField from '@mui/material/TextField';

import { debounce } from 'lodash';

type Props = {
  onResult: (result: string) => void;
};

const SearchBar = ({ onResult }: Props) => {
  const [searchTerm, setSearchTerm] = useState('');

  const onSearchTermChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const searchTermChanged = (searchTerm: string) => (searchTerm.length > 0 ? onResult(searchTerm) : null);

  const debounceTermChanged = useCallback(debounce(searchTermChanged, 700), []);

  useEffect(() => {
    debounceTermChanged(searchTerm);
  }, [searchTerm]);

  return (
    <>
      <TextField
        autoFocus
        fullWidth
        onChange={onSearchTermChange}
        id="standard-basic"
        variant="outlined"
        placeholder="Introduceti un termen de cautare"
        label="Cautare"
      />
    </>
  );
};

export default SearchBar;
