import { useState } from 'react';

import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Tab } from '@mui/material';

import { useCurrentClaims } from '../../store/slices/authorizationApi';
import Personal from './Personal';
import { RolesTable } from './RolesTable.tsx';
import { UsersTable } from './UsersTable.tsx';

const ProfileSettings = () => {
  const [value, setValue] = useState('1');
  const { data: claims } = useCurrentClaims();

  return (
    <TabContext value={value}>
      <TabList
        indicatorColor="primary"
        textColor="primary"
        onChange={(_, newValue) => setValue(newValue)}
        aria-label="Setari Profil"
      >
        <Tab label="Personal" value="1" />
        {claims?.some(claim => claim.type === 'Execute' && claim.resource.name === 'User') ? (
          <Tab label="Membri" value="2" />
        ) : null}

        {claims?.some(claim => claim.type === 'Execute' && claim.resource.name === 'Role') ? (
          <Tab label="Roluri" value="3" />
        ) : null}
      </TabList>
      <TabPanel value="1">
        <Personal />
      </TabPanel>
      <TabPanel value="2">
        <UsersTable />
      </TabPanel>
      <TabPanel value="3">
        <RolesTable />
      </TabPanel>
    </TabContext>
  );
};

export default ProfileSettings;
