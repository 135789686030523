import { useDispatch } from 'react-redux';

import { AuthenticationResult } from '@azure/msal-browser';
import { configureStore } from '@reduxjs/toolkit';
import { createApi, fetchBaseQuery, setupListeners } from '@reduxjs/toolkit/query/react';

import { loginRequest } from '../authConfig';
import { msalInstance } from '../main';
import { reportsApiUrl } from './settings/Local.ts';

export const sgdeApi = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: reportsApiUrl,
    mode: 'cors',
    credentials: 'include',
    prepareHeaders: async headers => {
      const account = msalInstance.getActiveAccount();
      const response = !account
        ? ({} as AuthenticationResult)
        : await msalInstance.acquireTokenSilent({ ...loginRequest, account });

      // If we have a token set in state, let's assume that we should be passing it.
      if (response.accessToken) {
        headers.set('authorization', `Bearer ${response.accessToken}`);
      }

      return headers;
    },
  }),
  endpoints: () => ({}),
  refetchOnReconnect: true,
});

export const sgdeStore = configureStore({
  reducer: {
    [sgdeApi.reducerPath]: sgdeApi.reducer,
  },
  middleware: getDefaultMiddleware => getDefaultMiddleware().concat(sgdeApi.middleware),
});

setupListeners(sgdeStore.dispatch);

export type RootState = ReturnType<typeof sgdeStore.getState>;
export type AppDispatch = typeof sgdeStore.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
