import DxfFileContents from '../../models/DxfFileContents';
import ImportDxfRequest from '../../models/ImportDxfRequest';

import { sgdeApi } from '../index';

const dxfApi = sgdeApi.enhanceEndpoints({ addTagTypes: ['DxfFiles'] }).injectEndpoints({
  endpoints: build => ({
    parseDxfFiles: build.mutation<DxfFileContents[], File[]>({
      query: dxfFiles => {
        const formData = new FormData();
        dxfFiles.forEach(file => formData.append(file.name, file));
        return { url: `/dxf/parse`, method: 'POST', body: formData };
      },
      invalidatesTags: ['DxfFiles'],
    }),
    importDxfFiles: build.mutation<void, ImportDxfRequest>({
      query: importDxfRequest => {
        return { url: `/dxf/import`, method: 'POST', body: importDxfRequest };
      },
      invalidatesTags: ['DxfFiles'],
    }),
  }),
});

export const { useParseDxfFilesMutation: useParseDxfFiles, useImportDxfFilesMutation: useImportDxfFiles } = dxfApi;
