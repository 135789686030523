import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  formControl: {
    margin: `${theme.spacing(1)} ${theme.spacing(0)}`,
    minWidth: 120,
    width: '100%',
  },
}));
