import { useState } from 'react';

import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import Dialog from '../Modal/Dialog';

type Props = {
  icon: JSX.Element;
  toOpen: (closeDialog: () => void) => JSX.Element | JSX.Element[];
  children: JSX.Element | JSX.Element[];
};

const MenuItemWithDialogAction = ({ icon, toOpen, children }: Props) => {
  const [isOpen, setOpen] = useState(false);

  return (
    <ListItem button key={'ImportDxf'} onClick={() => setOpen(true)}>
      <ListItemIcon>{{ ...icon }}</ListItemIcon>
      <ListItemText>{{ ...children }}</ListItemText>
      <Dialog
        maxWidth="md"
        onClose={e => {
          e.preventDefault();
          e.stopPropagation();
          setOpen(false);
        }}
        open={isOpen}
      >
        {{ ...toOpen(() => setOpen(false)) }}
      </Dialog>
    </ListItem>
  );
};

export default MenuItemWithDialogAction;
