import { KeyboardArrowDown } from "@mui/icons-material";
import {
  Button,
  Checkbox,
  Menu,
  MenuItem,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";

import {
  CLAIM_TYPE_VALUES,
  ClaimColors,
  ClaimDTO,
} from "../../models/DTO/authorization/claimDTO";
import { PolicyDTO } from "../../models/DTO/authorization/policyDTO";
import { ResourceDTO } from "../../models/DTO/authorization/resourceDTO";
import { RoleDTO } from "../../models/DTO/authorization/roleDTO";
import {
  useClaims,
  useDeleteRoles,
  usePolicies,
  useRecoverRoles,
  useResources,
  useRoles,
  useUpdatePolicy,
} from "../../store/slices/authorizationApi";
import { RolesTableHead } from "./RolesTableHead.tsx";
import { RolesTableToolbar } from "./RolesTableToolabar.tsx";
import { useState } from "react";

type Order = "asc" | "desc";

export const RolesTable = () => {
  const [order, setOrder] = useState<Order>("asc");
  const [orderBy, setOrderBy] =
    useState<keyof (RoleDTO & { resources: ResourceDTO[] })>("name");
  const [selected, setSelected] = useState<readonly number[]>([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const { data: roles } = useRoles();
  const { data: policies } = usePolicies();
  const { data: resources } = useResources();
  const [recoverRoles] = useRecoverRoles();
  const [deleteRoles] = useDeleteRoles();

  const handleRequestSort = (
    property: keyof (RoleDTO & { resources: ResourceDTO[] }),
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const onSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = roles?.map((r) => r.id) || [];
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const onSelect = (id: number) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected: readonly number[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - (roles?.length ?? 0)) : 0;

  return (
    <>
      <RolesTableToolbar selected={selected} />
      <TableContainer>
        <Table
          sx={{ minWidth: 750 }}
          aria-labelledby="tableTitle"
          size="medium"
        >
          <RolesTableHead
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onSelectAll={onSelectAll}
            onSort={handleRequestSort}
            rowCount={roles?.length ?? 0}
          />
          <TableBody>
            {stableSort(
              (roles?.map((role) => ({
                ...role,
                resources: policies?.reduce((resources, policy) => {
                  if (policy.role.id === role.id)
                    resources.push(policy.claim.resource);
                  return resources;
                }, [] as ResourceDTO[]),
              })) || []) as readonly (RoleDTO & { resources: ResourceDTO[] })[],
              getComparator(order, orderBy),
            )
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((role) => (
                <TableRow
                  hover
                  key={role.id}
                  selected={selected.includes(role.id)}
                >
                  <TableCell padding="checkbox">
                    <Checkbox
                      color="primary"
                      checked={selected.includes(role.id)}
                      onClick={() => onSelect(role.id)}
                    />
                  </TableCell>
                  <TableCell>{role.id}</TableCell>
                  <TableCell>{role.name}</TableCell>
                  <TableCell>
                    {resources?.map((resource) => (
                      <Resource
                        key={resource.id}
                        resource={resource}
                        role={role}
                      />
                    ))}
                  </TableCell>
                  <TableCell>
                    <Switch
                      checked={!role.softDeleted}
                      onChange={({ target }) =>
                        target.checked
                          ? recoverRoles([role.id])
                          : deleteRoles([role.id])
                      }
                    />
                  </TableCell>
                </TableRow>
              ))}
            {emptyRows > 0 && (
              <TableRow style={{ height: 71 * emptyRows }}>
                <TableCell colSpan={5} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={roles?.length ?? 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={(_, page) => setPage(page)}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
};

const Resource = ({
  resource,
  role,
}: {
  resource: ResourceDTO;
  role: RoleDTO;
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { data: policies } = usePolicies();
  const [updatePolicy] = useUpdatePolicy();
  const { data: claims } = useClaims();
  const open = Boolean(anchorEl);
  const currentClaimType = policies?.find(
    (p) => p.role.id === role.id && p.claim.resource.id === resource.id,
  )?.claim.type;

  return (
    <>
      <Button
        key={resource.id}
        sx={{ margin: "4px" }}
        onClick={(event) => setAnchorEl(event.currentTarget)}
        endIcon={<KeyboardArrowDown />}
        variant="outlined"
        color={ClaimColors[currentClaimType ?? "None"]}
      >
        {resource.name}
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {CLAIM_TYPE_VALUES.map((type) => (
          <MenuItem
            key={type}
            selected={currentClaimType === type}
            onClick={() => {
              const claim =
                claims?.find(
                  (c) => c.resource.id === resource.id && c.type === type,
                ) ?? ({ resource, type } as ClaimDTO);
              const policy =
                policies?.find(
                  (p) => p.role.id === role.id && p.claim.id === claim.id,
                ) ?? ({ role, claim } as PolicyDTO);
              updatePolicy(policy);
              setAnchorEl(null);
            }}
          >
            <Typography color={`${ClaimColors[type]}.main`}>{type}</Typography>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

const stableSort = <T extends RoleDTO & { resources: ResourceDTO[] }>(
  array: readonly T[],
  comparator: (a: T, b: T) => number,
) => {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
};

const getComparator = <
  Key extends keyof (RoleDTO & { resources: ResourceDTO[] }),
>(
  order: Order,
  orderBy: Key,
): ((
  a: RoleDTO & { resources: ResourceDTO[] },
  b: RoleDTO & { resources: ResourceDTO[] },
) => number) =>
  order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);

const descendingComparator = (
  a: RoleDTO & { resources: ResourceDTO[] },
  b: RoleDTO & { resources: ResourceDTO[] },
  key: keyof (RoleDTO & { resources: ResourceDTO[] }),
) =>
  typeof a[key] === "string"
    ? b[key]
        ?.toString()
        .localeCompare(a[key]?.toString(), undefined, { sensitivity: "accent" })
    : b[key] < a[key]
      ? -1
      : b[key] > a[key]
        ? 1
        : 0;
