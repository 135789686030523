import { SetState, StateCreator } from 'zustand';

import { ReportType } from '../../../models/ReportType';
import { AreaMeasurement } from '../../../models/measurement.model';
import { postResource } from '../../Fetch.ts';
import { reportsApiUrl } from '../../settings/Local.ts';
import { GlobalState } from '../../useStore.ts';

export interface ParcelMeasurementsState {
  parcelMeasurements: AreaMeasurement[];

  latestParcelMeasurements: AreaMeasurement[];

  getParcelMeasurements: (
    bounds?: number[][],
    customGeometry?: boolean,
    skip?: number,
    publicPageName?: string
  ) => void;

  cleanParcelMeasurements: () => void;
}

const createParcelMeasurementsSlice: StateCreator<ParcelMeasurementsState, SetState<GlobalState>> = (set, get) => ({
  parcelMeasurements: [],
  latestParcelMeasurements: [],
  getParcelMeasurements: async (bounds, customGeometry, skip = 0, publicPageName = '') => {
    const { measurements: latestParcelMeasurements, moreAvailable } = await postResource(
      `${reportsApiUrl}/parcele/measurements?reportTypes=${ReportType.Parcela}&skip=${skip}&take=1000&customGeometry=${customGeometry}&publicLink=${publicPageName}`,
      bounds
    );

    const parcelMeasurements = [...get().parcelMeasurements, ...(latestParcelMeasurements || [])];
    set({ parcelMeasurements });
    set({ latestParcelMeasurements });
    const newSkip = skip + 1000;
    if (moreAvailable) {
      await get().getParcelMeasurements(undefined, customGeometry, newSkip);
    }
  },
  cleanParcelMeasurements: () => {
    set({ latestParcelMeasurements: [] });
  },
});

export default createParcelMeasurementsSlice;
