import { useEffect } from 'react';

import { InteractionType } from '@azure/msal-browser';
import { AuthenticatedTemplate, useMsalAuthentication } from '@azure/msal-react';

import { Home } from '../../components/Home/Home';
import { Popup } from '../../components/Popup/Popup';
import AppMenu from '../../primitives/Menu/Menu';
import OpenLayersMapView from '../../primitives/OpenLayers/OpenLayersMapView';
import { usePublicPageSettingsByName } from '../../store/slices/publicPageApi';
import { useSettings } from '../../store/slices/settingsApi';
import { useCurrentUser } from '../../store/slices/usersApi';
import useStore from '../../store/useStore';

const usePrivateSetup = () => {
  useCurrentUser();
  const { data: settings } = useSettings();
  const setSettings = useStore(state => state.setSettings);

  useEffect(() => {
    setSettings({
      isReady: true,
      reportTypes: settings?.reportTypes,
      mapProvider: settings?.mapProvider,
      mapType: settings?.mapType,
      backgroundType: settings?.backgroundType,
      showGroundOverlays: settings?.showGroundOverlays,
      showStreetView: settings?.showStreetView,
      streetViewYears: settings?.streetViewYears,
      showCustomStyles: settings?.showCustomStyles,
      lastLocation: settings?.lastLocation,
    });
  }, [settings, setSettings]);
};

const usePublicSetup = ({ publicPageName }: { publicPageName: string }) => {
  const { data: publicSettings } = usePublicPageSettingsByName(publicPageName);
  const setSettings = useStore(state => state.setSettings);

  useEffect(() => {
    setSettings({
      isReady: true,
      isPublic: true,
      publicPageName: publicSettings?.pageLink,
      publicPageTitle: publicSettings?.pageTitle,
      publicPageDefaultZoom: publicSettings?.pageDefaultZoom,
      reportTypes: publicSettings?.mapSettings.reportTypes,
      mapProvider: publicSettings?.mapSettings.mapProvider,
      mapType: publicSettings?.mapSettings.mapType,
      backgroundType: publicSettings?.mapSettings.backgroundType,
      showGroundOverlays: publicSettings?.mapSettings.showGroundOverlays,
      showStreetView: publicSettings?.mapSettings.showStreetView,
      streetViewYears: publicSettings?.mapSettings.streetViewYears,
      showCustomStyles: publicSettings?.mapSettings.showCustomStyles,
      lastLocation: publicSettings?.mapSettings.lastLocation,
    });
  }, [publicSettings, setSettings]);
};

const PrivatePage = () => {
  useMsalAuthentication(InteractionType.Redirect);
  usePrivateSetup();

  return (
    <>
      <Popup />
      <AppMenu />
      <AuthenticatedTemplate>
        <Home />
      </AuthenticatedTemplate>
    </>
  );
};

const PublicPage = (props: { publicPageName: string }) => {
  usePublicSetup(props);

  return (
    <>
      <Popup />
      <AppMenu />
      <OpenLayersMapView />
    </>
  );
};

export const Pages = () => {
  const publicPageName = window.location.pathname.split('public/')[1];

  return publicPageName ? <PublicPage publicPageName={publicPageName} /> : <PrivatePage />;
};
