import { createContext, useCallback, useState } from 'react';
import SGDEFeature from '../../models/feature/sgde-feature';
import { ReportField } from '../../models/report-field.model';

type State = {
  isEdited: (feature: SGDEFeature) => boolean | null;
  canEdit: (feature: SGDEFeature) => boolean;
  setEdit: (feature: SGDEFeature) => void;
  saveEdit: () => SGDEFeature | null;
  setReportFields: (reportFields: ReportField[]) => void;
  getReportFields: () => ReportField[];
};

export const DataContext = createContext<Partial<State>>({});

type Props = {
  children: JSX.Element[] | JSX.Element;
};

export const DataProvider = ({ children }: Props) => {
  const [edited, setEdited] = useState<SGDEFeature | null>(null);

  const initialState = {
    isEdited: useCallback(
      (feature: SGDEFeature) => {
        return edited && feature.areaMeasurementId === edited.areaMeasurementId;
      },
      [edited]
    ),
    saveEdit: useCallback(() => {
      const feature = edited;
      setEdited(null);
      return feature;
    }, [edited, setEdited]),
    setEdit: useCallback(
      (feature: SGDEFeature) => {
        setEdited(feature);
      },
      [setEdited]
    ),
  };

  return <DataContext.Provider value={initialState}>{children}</DataContext.Provider>;
};
