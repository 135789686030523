const ReportType = {
  Masuratoare: -1,
  Imobil: 1,
  Constructie: 2,
  Proprietar: 3,
  Patrimoniu: 4,
  Camin: 5,
  Conducta: 6,
  Parcela: 7,
} as const;

type ReportTypesAsValues = (typeof ReportType)[keyof typeof ReportType];
type ReportTypes = keyof typeof ReportType | ReportTypesAsValues;

const findReportTypeById = (id: number) => {
  const result = Object.entries(ReportType).find(([, value]) => value === id);
  return result ? result[0] : null;
};
export type { ReportTypesAsValues, ReportTypes };
export { ReportType, findReportTypeById };
