import { useContext, useEffect, useState } from "react";

import DirectionsIcon from "@mui/icons-material/Directions";
import MapIcon from "@mui/icons-material/Map";
import {
  Button,
  Grid,
  MenuItem,
  Pagination,
  Paper,
  Select,
  SelectChangeEvent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";

import { ReportFieldDefinition } from "../../models/report-field-definition.model";
import { Report } from "../../models/report.model";
import { ProgressContext } from "../../primitives/Progress/Progress";
import { getResource } from "../../store/Fetch";
import { reportsApiUrl } from "../../store/settings/Local";
import { useSearchReports } from "../../store/slices/searchApi";

import SearchBar from "../../primitives/SearchBar/SearchBar";
import { ReportType } from "../../models/ReportType";

const DEFAULT_PAGE_SIZE = 20;

type Props = {
  reportTypes: number[];
  hideCheckboxes?: boolean;
  hideNamePropertyInColumnList?: boolean;
  showNavigateIcon?: boolean;
  onSelectedReport: (report: Report) => void;
};

const SearchReport = ({
  reportTypes,
  hideCheckboxes,
  hideNamePropertyInColumnList,
  showNavigateIcon,
  onSelectedReport,
}: Props) => {
  const [checked, setChecked] = useState(0);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [fieldsToShowInReport, setFieldsToShowInReport] = useState<
    ReportFieldDefinition[]
  >([]);
  const [searchReports, { data: searchResults, isLoading }] =
    useSearchReports();
  const [selectedReportType, setSelectedReportType] = useState<number>(
    reportTypes[0],
  );
  const { dispatch: progress } = useContext(ProgressContext);

  const onSearchTerm = (searchTerm: string) => {
    setSearchTerm(searchTerm);
  };

  const handleToggle = (reportId?: number) => {
    if (!reportId) return;
    setChecked(reportId);
    const selectedReport = searchResults?.items.find(
      (report) => report.id === reportId,
    );
    selectedReport && onSelectedReport(selectedReport);
  };

  const getFieldsToShowInReport = async () => {
    const fieldsToShowInReportResult: ReportFieldDefinition[] =
      await getResource(
        `${reportsApiUrl}/reportFieldsDefinition/fieldstoshowinreport/${selectedReportType}`,
      );
    setFieldsToShowInReport(fieldsToShowInReportResult);
  };

  const onPaginationChange = (page: number) => {
    setCurrentPage(page);
  };

  const getMapIconIfReportHasMeasurement = (report: Report) => {
    const reportHasMeasurement = report?.reportFields?.some(
      (field) => field?.reportFieldDefinition?.id === 35,
    );
    if (reportHasMeasurement) {
      return <MapIcon />;
    }

    return <></>;
  };

  useEffect(() => {
    isLoading && progress?.("show");
    !isLoading && progress?.("hide");
  }, [isLoading, progress]);

  useEffect(() => {
    setSelectedReportType(reportTypes[0]);
  }, [reportTypes]);

  useEffect(() => {
    if (selectedReportType) {
      getFieldsToShowInReport();
    }
  }, [selectedReportType]);

  useEffect(() => {
    if (searchTerm) {
      searchReports({
        searchReportTypeId: selectedReportType,
        pageSize: DEFAULT_PAGE_SIZE,
        currentPage: currentPage,
        searchTerm,
      });
    }
  }, [searchTerm, currentPage, selectedReportType, searchReports]);

  const handleSelectedReportTypeChange = (event: SelectChangeEvent<number>) => {
    setSelectedReportType(event.target.value as number);
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={reportTypes.length > 1 ? 8 : 12}>
          <SearchBar onResult={onSearchTerm}></SearchBar>
        </Grid>
        {reportTypes.length > 1 && (
          <Grid item xs={4}>
            <Select
              fullWidth
              value={selectedReportType}
              label="Tip Raport"
              onChange={handleSelectedReportTypeChange}
            >
              {Object.entries(ReportType)
                .filter(([, value]) => reportTypes.includes(value))
                .map(([type, id]) => (
                  <MenuItem key={id} value={id}>
                    {type}
                  </MenuItem>
                ))}
            </Select>
          </Grid>
        )}
      </Grid>
      {searchResults && searchResults.items.length > 0 && (
        <TableContainer
          style={{
            marginTop: "20px",
            marginBottom: "20px",
          }}
          component={Paper}
        >
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell style={{ width: "8%" }}></TableCell>
                <TableCell style={{ width: "2%" }}></TableCell>
                {hideNamePropertyInColumnList !== true && (
                  <TableCell>Nume</TableCell>
                )}
                {fieldsToShowInReport.length &&
                  fieldsToShowInReport?.map((fieldToShow) => {
                    return <TableCell>{fieldToShow?.fieldName}</TableCell>;
                  })}
              </TableRow>
            </TableHead>
            <TableBody>
              {searchResults?.items.map((report) => (
                <TableRow>
                  {hideCheckboxes !== true && (
                    <TableCell>
                      <Checkbox
                        edge="end"
                        onChange={() => handleToggle(report?.id)}
                        checked={checked == report.id}
                        inputProps={{
                          "aria-labelledby": report?.id?.toString(),
                        }}
                      />
                    </TableCell>
                  )}
                  {showNavigateIcon === true && (
                    <TableCell>
                      <Button>
                        <DirectionsIcon
                          onClick={() => handleToggle(report?.id)}
                        />
                      </Button>
                    </TableCell>
                  )}
                  <TableCell>
                    {getMapIconIfReportHasMeasurement(report)}
                  </TableCell>
                  {hideNamePropertyInColumnList !== true && (
                    <TableCell>{report?.name}</TableCell>
                  )}
                  {fieldsToShowInReport.length &&
                    fieldsToShowInReport?.map((fieldToShow) => {
                      const field = report?.reportFields?.find((field) => {
                        return (
                          field?.reportFieldDefinition?.fieldName ===
                          fieldToShow?.fieldName
                        );
                      });
                      return <TableCell>{field?.value}</TableCell>;
                    })}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {searchResults &&
        searchResults.items.length < searchResults.totalItems && (
          <Pagination
            count={Math.ceil(searchResults.totalItems / DEFAULT_PAGE_SIZE)}
            page={currentPage}
            color="primary"
            onChange={(_, page: number) => onPaginationChange(page)}
          />
        )}
    </>
  );
};

export default SearchReport;
