import { Provider as StoreProvider } from 'react-redux';

import { StyledEngineProvider, ThemeOptions, ThemeProvider, createTheme } from '@mui/material/styles';

import { IPublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import '@fontsource-variable/overpass';
import 'long-press-event/dist/long-press-event.min';
import 'ol-contextmenu/ol-contextmenu.css';
import 'ol/ol.css';

import { Pages } from './components/Pages/Pages';
import { DataProvider } from './primitives/Data/DataContext';
import { ModalProvider } from './primitives/Modal/ModalContext';
import { NotificationProvider } from './primitives/Notification/Notification';
import { ProgressProvider } from './primitives/Progress/Progress';
import { sgdeStore } from './store';

import './App.css';

const baseTheme: ThemeOptions = {
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        '*': {
          boxSizing: 'border-box',
        },
        'html, body, #root': {
          height: '100%',
          overflow: 'hidden',
        },

        '::-webkit-scrollbar': {
          width: 15,
        },
        '::-webkit-scrollbar-track': {
          backgroundColor: 'transparent',
          backgroundClip: 'content-box',
          border: '7px solid transparent',
        },
        '::-webkit-scrollbar-thumb': {
          backgroundClip: 'content-box',
          borderRadius: 12,
          backgroundColor: '#9e9e9e',
          border: '6px solid transparent',
        },
        '::-webkit-scrollbar-thumb:hover': {
          border: '4px solid transparent',
        },
        '::-webkit-scrollbar-corner': {
          backgroundColor: 'transparent',
        },
      },
    },
    // MuiLink: {
    //   defaultProps: {
    //     component: RouterLink,
    //   } as LinkProps,
    // },
    // MuiButtonBase: {
    //   defaultProps: {
    //     LinkComponent: RouterLink,
    //   },
    // },RouterLink
  },
  typography: {
    fontFamily: ['Overpass Variable', 'sans-serif'].join(','),
    allVariants: {
      letterSpacing: -1,
    },
  },
};

const theme = createTheme({
  ...baseTheme,
  palette: {
    mode: 'dark',
    primary: { main: '#0fb9f3' },
    secondary: { main: '#dece2d' },
    success: { main: '#8aae0e' },
    error: { main: '#ee6055' },
    background: { default: '#0B2027', paper: '#0B2027' },
  },
});

const App = ({ pca }: { pca: IPublicClientApplication }) => (
  <StyledEngineProvider injectFirst>
    <ThemeProvider theme={theme}>
      <MsalProvider instance={pca}>
        <StoreProvider store={sgdeStore}>
          <div className="App">
            <ProgressProvider>
              <NotificationProvider>
                <DataProvider>
                  <ModalProvider>
                    <Pages />
                  </ModalProvider>
                </DataProvider>
              </NotificationProvider>
            </ProgressProvider>
          </div>
        </StoreProvider>
      </MsalProvider>
    </ThemeProvider>
  </StyledEngineProvider>
);

export default App;
