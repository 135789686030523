const isAlphaNumeric = (str: string) => {
  return str && /^[a-zA-Z0-9]+$/i.test(str);
};

const generateRandomString = (length: number) => {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789&*.@#$%';
  const charactersLength = characters.length;
  const crypto = window.crypto;
  const array = new Uint8Array(length);
  crypto.getRandomValues(array);
  array.forEach(x => (result += characters[x % charactersLength]));
  return result;
};

export { isAlphaNumeric, generateRandomString };
