import { Fill, Stroke, Style } from 'ol/style';

import { ReportTypeStyles } from './map-utils';

// const reportOptionsMap: { [key: number]: ReportOption } = {
//     [ReportType.Imobil]: {
//       fillColor: '#9e9900',
//       fillOpacity: 0.4,
//       strokeColor: '#006ab0',
//       strokeWidth: 4,
//       zIndex: 2,
//     },
//     [ReportType.Constructie]: {
//       fillColor: '#5EC5DB',
//       fillOpacity: 0.4,
//       strokeColor: '#b04600',
//       strokeWidth: 4,
//       zIndex: 3,
//     },
//     [ReportType.Patrimoniu]: {
//       fillColor: '#c634eb',
//       fillOpacity: 0.4,
//       strokeColor: '#4c34eb',
//       strokeWidth: 4,
//       zIndex: 2,
//     },
//     [ReportType.Proprietar]: {
//       fillColor: '#c634eb',
//       fillOpacity: 0.4,
//       strokeColor: '#4c34eb',
//       strokeWidth: 4,
//       zIndex: 2,
//     },
//     [ReportType.Masuratoare]: {
//       fillColor: '#000000',
//       fillOpacity: 0.4,
//       strokeColor: '#8a8a8a',
//       strokeWidth: 4,
//       zIndex: 4,
//     },
//     [ReportType.Conducta]: {
//       fillColor: '#0096FF',
//       fillOpacity: 0.4,
//       strokeColor: '#474747',
//       strokeWidth: 6,
//       zIndex: 5,
//       backgroundByState: {
//         1: '#474747',
//         2: '#ff7700',
//         3: '#ff7700',
//         4: '#ff1100',
//       },
//       backgroundByType: {
//         'Conducta apa': '#020473',
//         'Conducta canalizare': '#785034',
//         'Racord canalizare': '#785034',
//         'Bransament apa': '#020473',
//         Gaz: '#d4c200',
//         'Energie Electrica': '#5659fc',
//       },
//     },
//     [ReportType.Camin]: {
//       fillColor: '#575757',
//       fillOpacity: 1,
//       strokeColor: '#000000',
//       strokeWidth: 4,
//       zIndex: 6,
//     },
//   };

const reportTypeStyles: ReportTypeStyles = {
  '-1': new Style({
    stroke: new Stroke({
      color: '#8a8a8a',
      width: 4,
    }),
    fill: new Fill({
      color: 'rgba(0, 0, 0, 0.4)',
    }),
  }),
  '1': new Style({
    stroke: new Stroke({
      color: '#006ab0',
      width: 4,
    }),
    fill: new Fill({
      color: 'rgba(158, 153, 0, 0.4)',
    }),
  }),
  '2': new Style({
    stroke: new Stroke({
      color: '#b04600',
      width: 4,
    }),
    fill: new Fill({
      color: 'rgba(94, 197, 219, 0.4)',
    }),
  }),
  '4': new Style({
    stroke: new Stroke({
      color: '#4c34eb',
      width: 4,
    }),
    fill: new Fill({
      color: 'rgba(198, 52, 235, 0.4)',
    }),
  }),
  '5': new Style({
    stroke: new Stroke({
      color: '#000000',
      width: 4,
    }),
    fill: new Fill({
      color: 'rgba(87, 87, 87, 0.4)',
    }),
  }),
  '6': new Style({
    stroke: new Stroke({
      color: '#474747',
      width: 4,
    }),
    fill: new Fill({
      color: 'rgba(0, 150, 255, 0.4)',
    }),
  }),
  '7': new Style({
    stroke: new Stroke({
      color: '#878747',
      width: 4,
    }),
    fill: new Fill({
      color: 'rgba(150, 150, 50, 0.4)',
    }),
  }),
};

export { reportTypeStyles };

//   const styles = new Style({
//     stroke: new Stroke({
//       color: 'blue',
//       lineDash: [4],
//       width: 3,
//     }),
//     fill: new Fill({
//       color: 'rgba(0, 0, 255, 0.1)',
//     }),
//   });
