import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  ol_popup: {
    position: 'absolute',
    'background-color': 'white',
    'box-shadow': '0 1px 4px rgba(0,0,0,0.2)',
    padding: '8px',
    'border-radius': '10px',
    border: '1px solid #cccccc',
    bottom: '12px',
    left: '-50px',
    'min-width': '50px',
    whiteSpace: 'nowrap',
    textAlign: 'left',
    color: 'black',
    width: 'max-content',
    '&:after, :before': {
      top: '100%',
      border: 'solid transparent',
      content: ' ',
      height: 0,
      width: 0,
      position: 'absolute',
      'pointer-events': 'none',
    },
    '&:after': {
      'border-top-color': 'white',
      'border-width': '10px',
      left: '48px',
      'margin-left': '-10px',
    },
    '&:before': {
      'border-top-color': '#cccccc',
      'border-width': '11px',
      left: '48px',
      'margin-left': '-11px',
    },
  },
}));
