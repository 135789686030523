import { useEffect, useState } from 'react';

import SearchIcon from '@mui/icons-material/Search';
import { IconButton } from '@mui/material';

import { SkipToken, skipToken } from '@reduxjs/toolkit/query';

import SearchReport from '../../components/SearchReport/SearchReport';
import { Report } from '../../models/report.model';
import Settings from '../../models/settings/settings.model';
import { useMeasurementsById } from '../../store/slices/measurementsApi';
import { useReportFieldsById } from '../../store/slices/reportsApi';

import { DialogAction } from '../Modal/ModalContext';

interface MenuSearchProps {
  setZoom: (mapZoom: number) => void;
  setSettings: (settings: any) => void;
  settings: Settings;
  modal: ((action: DialogAction) => void) | undefined;
}

const SearchableReportTypes = [1, 2, 4, 5, 6];

const MenuSearch = (props: MenuSearchProps) => {
  const { setZoom, setSettings, settings, modal } = props;
  const [report, setReport] = useState<Report>();
  const [measurementId, setMeasurementId] = useState<string | SkipToken>(skipToken);
  const { data: reportFields } = useReportFieldsById(report?.id ?? skipToken);
  const { data: measurements } = useMeasurementsById(measurementId);

  useEffect(() => {
    if (reportFields) {
      const reportFieldDefinition = reportFields.find(rf => rf.reportFieldDefinitionId === 35);
      if (reportFieldDefinition?.value) setMeasurementId(reportFieldDefinition.value);
    }
  }, [reportFields, setMeasurementId]);

  useEffect(() => {
    if (!measurements) return;

    const areaMeasurement = { ...measurements[0] };

    if (!report) return;
    areaMeasurement.reportTypeId = report.reportTypeId;
    areaMeasurement.parentReportId = report.id;
    const newSettings = { ...settings, currentLocationSetting: areaMeasurement };
    setZoom(14);
    setSettings({ ...newSettings });
    if (areaMeasurement.coordinatesArray && areaMeasurement.coordinatesArray.length > 0) {
      modal?.({ type: 'close' });
    }
  }, [reportFields, measurements, modal, setSettings, setZoom]);

  const onSearchTerrainClick = () => {
    modal?.({
      type: 'open',
      title: 'Cautare Imobil',
      width: 'lg',
      mainContent: (
        <div>
          <SearchReport
            reportTypes={SearchableReportTypes}
            onSelectedReport={(selected: Report) => {
              selected.id && setReport({ ...selected });
            }}
            hideCheckboxes={true}
            hideNamePropertyInColumnList={true}
            showNavigateIcon={true}
          ></SearchReport>
        </div>
      ),
    });
  };

  return (
    <IconButton color="inherit" onClick={onSearchTerrainClick} edge="end" size="large">
      <SearchIcon />
    </IconButton>
  );
};

export default MenuSearch;
