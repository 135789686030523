import {DialogContent, DialogTitle} from '@mui/material';
import {useEffect, useState} from 'react';
import SGDEFeature from '../../models/feature/sgde-feature';
import Dialog from '../Modal/Dialog';
import PanoramaViewer from '../PanoramaViewer/PanoramaViewer';
import {reportsApiUrl} from "../../store/settings/Local.ts";

type Props = {
    feature?: SGDEFeature;
};

const StreetView = ({feature}: Props) => {
    const [showStreetViewModal, setShowStreetViewModal] = useState<boolean>(false);
    const [streetViewTitle, setStreetViewTitle] = useState<string>();
    const [imageName, setImageName] = useState<string>();
    const [imagePath, setImagePath] = useState<string>();

    useEffect(() => {
        if (!feature) return;
        const streetViewItemId = feature.getId();
        if (streetViewItemId) {
            const imagePath = `${reportsApiUrl}/streetview/${streetViewItemId}/download`;
            setImagePath(imagePath);
            setImageName(imageName);
        }

        setShowStreetViewModal(true);
        const imageDate = new Date(feature.get('dateTaken'));
        setStreetViewTitle(
            `Imagine 360 (${getMonth(imageDate.getMonth())} ${imageDate.getFullYear()} - ${feature.get('imageName')})`
        );
    }, [feature]);

    const getMonth = (monthNumber: number) => {
        const months = [
            'Ianuarie',
            'Februarie',
            'Martie',
            'Aprilie',
            'Mai',
            'Iunie',
            'Iulie',
            'August',
            'Septembrie',
            'Octombrie',
            'Noiembrie',
            'Decembrie',
        ];
        return months[monthNumber];
    };

    const closeDialog = () => {
        setShowStreetViewModal(false);
    };

    return (
        <Dialog maxWidth="md" onClose={closeDialog} open={showStreetViewModal}>
            <DialogTitle>{streetViewTitle}</DialogTitle>
            <DialogContent>
                <PanoramaViewer imageName={imageName} imagePath={imagePath}/>
            </DialogContent>
        </Dialog>
    );
};

export default StreetView;
