import { SetState, StateCreator } from 'zustand';

import { ReportType } from '../../../models/ReportType';
import { AreaMeasurement } from '../../../models/measurement.model';
import { postResource } from '../../Fetch.ts';
import { reportsApiUrl } from '../../settings/Local.ts';
import { GlobalState } from '../../useStore.ts';

export interface BuildingMeasurementsState {
  buildingMeasurements: AreaMeasurement[];

  latestBuildingMeasurements: AreaMeasurement[];

  getBuildingMeasurements: (
    bounds?: number[][],
    customGeometry?: boolean,
    skip?: number,
    publicPageName?: string
  ) => void;

  cleanBuildingMeasurements: () => void;
}

const createBuildingMeasurementsSlice: StateCreator<BuildingMeasurementsState, SetState<GlobalState>> = (set, get) => ({
  buildingMeasurements: [],
  latestBuildingMeasurements: [],
  getBuildingMeasurements: async (bounds, customGeometry, skip = 0, publicPageName = '') => {
    const { measurements: latestBuildingMeasurements, moreAvailable } = await postResource(
      `${reportsApiUrl}/constructii/measurements?reportTypes=${ReportType.Constructie}&skip=${skip}&take=1000&customGeometry=${customGeometry}&publicLink=${publicPageName}`,
      bounds
    );

    const buildingMeasurements = [...get().buildingMeasurements, ...(latestBuildingMeasurements || [])];
    set({ buildingMeasurements });
    set({ latestBuildingMeasurements });
    const newSkip = skip + 1000;
    if (moreAvailable) {
      await get().getBuildingMeasurements(undefined, customGeometry, newSkip);
    }
  },
  cleanBuildingMeasurements: () => {
    set({ latestBuildingMeasurements: [] });
  },
});

export default createBuildingMeasurementsSlice;
