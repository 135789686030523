import { SetState, StateCreator } from 'zustand';

import { AreaMeasurement, CreateAreaMeasurement } from '../../models/measurement.model';
import { getResource, postResource } from '../Fetch';
import { reportsApiUrl } from '../settings/Local';
import { GlobalState } from '../useStore.ts';

export interface MeasurementsState {
  measurements: AreaMeasurement[];

  emptyMeasurements: AreaMeasurement[];

  latestMeasurements: AreaMeasurement[];

  latestEmptyMeasurements: AreaMeasurement[];

  currentSkip: number;

  currentEmptySkip: number;

  reset: () => void;

  getMeasurements: (reportTypes: number[]) => void;

  getEmptyMeasurements: () => void;

  addMeasurement: (measurement: CreateAreaMeasurement) => Promise<AreaMeasurement>;

  updateMeasurement: () => void;
}

const createMeasurementsSlice: StateCreator<MeasurementsState, SetState<GlobalState>> = (set, get) => ({
  measurements: [],
  emptyMeasurements: [],
  latestMeasurements: [],
  latestEmptyMeasurements: [],
  currentSkip: 0,
  currentEmptySkip: 0,
  reset: () => {
    set({
      currentSkip: 0,
      currentEmptySkip: 0,
    });
  },
  getMeasurements: async reportTypes => {
    const reportTypesToSearch = reportTypes?.filter(rt => rt !== -1) || [];
    const reportTypesString = reportTypesToSearch.reduce((acc, curr, index) => {
      return acc + `${index !== 0 ? '&' : ''}reportTypes=${curr}`;
    }, '?');

    const { measurements: latestMeasurements, moreAvailable } = await getResource(
      `${reportsApiUrl}/constructii/measurements${reportTypesString}&skip=${get().currentSkip}&take=1000`
    );

    const measurements = [...get().measurements, ...latestMeasurements];
    set({ measurements });
    set({ latestMeasurements });
    const currentSkip = get().currentSkip + 1000;
    set({ currentSkip });
    if (moreAvailable) {
      await get().getMeasurements(reportTypes);
    }
  },
  getEmptyMeasurements: async () => {
    const { measurements: latestEmptyMeasurements, moreAvailable } = await getResource(
      `${reportsApiUrl}/constructii/measurements/empty?skip=${get().currentEmptySkip}&take=1000`
    );
    const emptyMeasurements = [...get().emptyMeasurements, latestEmptyMeasurements];
    set({ emptyMeasurements });
    set({ latestMeasurements: [...get().latestMeasurements, ...latestEmptyMeasurements] });
    const currentEmptySkip = get().currentEmptySkip + 1000;
    set({ currentEmptySkip });
    if (moreAvailable) {
      await get().getEmptyMeasurements();
    }
  },
  addMeasurement: async measurement => await postResource(`${reportsApiUrl}/measurements/`, measurement),
  updateMeasurement: async () => {},
});

export default createMeasurementsSlice;
