import { MultipleItemDto } from '../../models/DTO/multiple/multipleItemDTO';

import { SumParentField } from '../sum-parent-field';

// totalFieldId - multipleFieldDefinitionId from DB
export const totalSumParentFields: SumParentField[] = [
  { totalFieldId: 24, groupId: [28], inputFieldIds: [38] },
  { totalFieldId: 25, groupId: [39], inputFieldIds: [46] },
  { totalFieldId: 26, groupId: [47], inputFieldIds: [54] },
  { totalFieldId: 27, groupId: [28, 39, 47], inputFieldIds: [38, 46, 54] },
];

export const inputFieldIdsForTotals = [28, 39, 47];

export const totalFields: Partial<MultipleItemDto>[] = [
  { key: '', value: '0', multipleId: undefined, multipleFieldsDefinitionId: 24 },
  { key: '', value: '0', multipleId: undefined, multipleFieldsDefinitionId: 25 },
  { key: '', value: '0', multipleId: undefined, multipleFieldsDefinitionId: 26 },
  { key: '', value: '0', multipleId: undefined, multipleFieldsDefinitionId: 27 },
];

export const yearGroupFieldId: number = 125;
