import { useState } from 'react';

import { Add as AddIcon, Delete as DeleteIcon, RestoreFromTrash as RecoverIcon } from '@mui/icons-material';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Toolbar,
  Tooltip,
  Typography,
  alpha,
} from '@mui/material';

import { useAddRole, useDeleteRoles, useRecoverRoles } from '../../store/slices/authorizationApi';
import TextField from '../Form/TextField';

interface Props {
  selected: readonly number[];
}

export const RolesTableToolbar = ({ selected }: Props) => {
  const [recoverRoles] = useRecoverRoles();
  const [deleteRoles] = useDeleteRoles();
  const [openAddRole, setAddRole] = useState(false);

  return (
    <Toolbar
      sx={{
        display: 'flex',
        ...(selected.length > 0 && {
          bgcolor: theme => alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        }),
      }}
    >
      {selected.length > 0 ? (
        <>
          <Typography sx={{ flex: '1 1 100%' }} color="inherit" variant="subtitle1" component="div">
            {selected.length} selectati
          </Typography>

          <Tooltip title="Restabileste">
            <IconButton onClick={() => recoverRoles([...selected])}>
              <RecoverIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Sterge">
            <IconButton onClick={() => deleteRoles([...selected])}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </>
      ) : (
        <>
          <Tooltip title="Adauga">
            <IconButton sx={{ ml: 'auto', boxShadow: 3 }} color="primary" onClick={() => setAddRole(true)}>
              <AddIcon fontSize="large" />
            </IconButton>
          </Tooltip>
          <AddRoleDialog open={openAddRole} handleClose={() => setAddRole(false)} />
        </>
      )}
    </Toolbar>
  );
};

const AddRoleDialog = ({ open, handleClose }: { open: boolean; handleClose: () => void }) => {
  const [error, setError] = useState(false);
  const [roleName, setRoleName] = useState<string>();
  const [addRole] = useAddRole();

  return (
    <Dialog open={open} onClose={handleClose} keepMounted={false}>
      <DialogContent>
        <TextField
          required
          autoFocus
          fullWidth
          margin="dense"
          label="Denumire rol"
          onChange={e => setRoleName(e.target.value.trim())}
          error={error}
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setError(false);
            handleClose();
          }}
        >
          Anulare
        </Button>
        <Button
          onClick={() => {
            if (roleName) {
              addRole({ name: roleName });
              handleClose();
            } else {
              setError(true);
            }
          }}
        >
          Creaza
        </Button>
      </DialogActions>
    </Dialog>
  );
};
